/* eslint-disable no-alert */
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import updatesCountInterceptors from 'src/api/interceptors/UpdatesCount.interceptors'
import { onlyUnique } from 'src/common/util'

let isBackButtonClicked = false

let locationKeys: string[] = []

let currentPathCheck = ''

function HandleBrowserBackButton() {
  const history = useHistory()

  useEffect(() => {
    window.onbeforeunload = function () {
      if (updatesCountInterceptors.getUserUpdatesCount() > 0) {
        return 'Data will be lost if you leave the page, are you sure?'
      }
      return null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return history.listen((location: any) => {
      if (history.action === 'POP') {
        if (locationKeys[1] === location.pathname) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [_, ...keys] = locationKeys
          locationKeys = keys
        } else {
          // check API calls are currently in flight
          if (!isBackButtonClicked) {
            if (updatesCountInterceptors.getStatisticsUpdates()[currentPathCheck] > 0) {
              if (window.confirm('Leave site? Changes you made may not be saved.')) {
                isBackButtonClicked = true
                currentPathCheck = window.location.pathname
                locationKeys = onlyUnique([location.pathname, ...locationKeys])
                history.go(-1) // go back
              } else {
                window.history.pushState(null, '', window.location.pathname)
                isBackButtonClicked = false
              }
            } else {
              locationKeys = onlyUnique([location.pathname, ...locationKeys])
              currentPathCheck = window.location.pathname
              isBackButtonClicked = true
              history.go(-1)
            }
          }

          if (currentPathCheck !== window.location.pathname) {
            isBackButtonClicked = false
            currentPathCheck = window.location.pathname
            window.history.pushState(null, '', window.location.pathname)
          }
        }
      }
    })
  }, [history])

  useEffect(() => {
    if (history.action === 'PUSH') {
      locationKeys = [history.location.pathname]
      isBackButtonClicked = false
      currentPathCheck = window.location.pathname
      window.history.pushState(null, null as unknown as string, window.location.pathname)
    }
  }, [history, history.location.pathname])

  return <></>
}

export default HandleBrowserBackButton
