import { AxiosRequestConfig } from 'axios'
import { IOrders, IOrderStemsOfOrder } from 'src/common/common.interface'
import { InputFieldEnum } from 'src/common/enum'
import { deleteHttp, getHttp, postHttp, putHttp } from './apiLayer'

export interface CreateOrdersPayload {
  eventOrderId: number
  supplier: string
}

export interface CreateOrderResponse {
  id: number
  insertedAt: Date
  updatedAt: Date
  supplier: string
}

export interface CreateOrdersStemPayload {
  quantity?: number
  supplierStemId?: number
  stemId?: number
  supplier?: string
  stemSupplierId?: number | null
  substitutes?: string
  subStemId?: number
  hardGoodSupplierId?: number
}

export interface CreateOrdersHardGoodPayload {
  quantity?: number
  hardGoodId?: number
  supplier?: string
}

export interface CreateOrderStemResponse extends IOrderStemsOfOrder {
  insertedAt: Date
  updatedAt: Date
}

export interface OrderStemPayload {
  stemId?: number
  orderStemId?: number
  supplierStemId?: number
  quantity: number
}

export interface MultiOrderPayload {
  supplier: string
  orderStems?: OrderStemPayload[]
}

export interface UpdateOrderUrlPayload {
  orderUrl: string
}

export interface UpdateTotalOrderPayload {
  total: number
}

export interface InitialOrderPayload {
  isResetOrder: boolean
}

export interface UpdateInputDataForOrderHardGoodPayload {
  inputField: InputFieldEnum
  supplierIdForOrderHardGood?: string
  quantityForOrderHardGood?: number
  priceForOrderHardGood?: number
  deliverDateForOrder?: string
  orderUrlForOrder?: string
}

export interface AddReSourceOrderPayload {
  type: string
  owner: string
  eventOrderId: number
  supplierId: number
  supplier: string
  orderTotal?: number
  freightTotal?: number
  orderUrl?: string
  orderStems?: number[]
  orderHardGoods?: number[]
}

export const createOrders = async (payload: CreateOrdersPayload): Promise<CreateOrderResponse> => {
  const data = await postHttp('/orders', payload)
  return data.data
}

export const getOrders = async (config?: AxiosRequestConfig): Promise<IOrders[]> => {
  const data = await getHttp('/orders', config)
  return data?.data?.result ?? []
}

export const deleteOrders = async (orderId: number) => {
  const data = await deleteHttp(`/orders/${orderId}`)
  return data.data
}

export const updateOrdersStem = async (orderId: number, orderStemId: number, payload: CreateOrdersStemPayload) => {
  const data = await putHttp(`orders/${orderId}/stems/${orderStemId}`, payload)
  return data.data
}

export const deleteOrderStem = async (orderId: number, orderStemId: number) => {
  const data = await deleteHttp(`/orders/${orderId}/stems/${orderStemId}`)
  return data.data
}

export const createOrderWithMultiStemsForEventOrder = async (
  eventOrderId: number,
  payload: CreateOrdersStemPayload[]
) => {
  const data = await putHttp(`eventOrders/${eventOrderId}/stemses`, payload)
  return data.data
}

export const initialCheckingOrders = async (
  eventOrderId: number,
  payload: InitialOrderPayload = { isResetOrder: false }
) => {
  const data = await putHttp(`/eventOrders/${eventOrderId}/orders`, payload)
  return data.data.result
}

export const updateOrderUrl = async (orderId: number, payload: UpdateOrderUrlPayload) => {
  const data = await putHttp(`orders/${orderId}/update-order-url`, payload)
  return data.data
}

export const updateTotalOrder = async (orderId: number, payload: UpdateTotalOrderPayload) => {
  const data = await putHttp(`orders/${orderId}/update-order-total`, payload)
  return data.data
}

export const updateOrderInfo = async (orderId: number, payload: Partial<IOrders>) => {
  const data = await putHttp(`orders/${orderId}/update-order`, payload)
  return data.data
}

export const addReSourceOrder = async (payload: AddReSourceOrderPayload) => {
  const data = await postHttp(`add-re-source-order`, payload)
  return data.data
}

export const updateOrdersHardGood = async (
  orderId: number,
  orderHardGoodId: number,
  payload: CreateOrdersStemPayload
) => {
  const data = await putHttp(`orders/${orderId}/hard-goods/${orderHardGoodId}`, payload)
  return data.data.result
}

export const createOrderWithMultiHardGoodsForEventOrder = async (
  eventOrderId: number,
  payload: CreateOrdersHardGoodPayload[]
) => {
  const data = await putHttp(`eventOrders/${eventOrderId}/hard-goods`, payload)
  return data.data.result
}

export const updateInputDataForOrderHardGood = async (
  orderId: number,
  orderHardGoodId: number,
  payload: UpdateInputDataForOrderHardGoodPayload
) => {
  const data = await putHttp(`orders/${orderId}/update-input-data/${orderHardGoodId}`, payload)
  return data.data
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDistanceMatrixData = async (origins: string, destinations: string): Promise<any> => {
  const data = getHttp(`/v1/distance-matrix/${encodeURIComponent(origins)}/${encodeURIComponent(destinations)}`)
  return data ?? []
}
