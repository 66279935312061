import createSagaMiddleware from 'redux-saga'
import { configureStore } from '@reduxjs/toolkit'

import rootReducer from './reducers'
import sagas from './sagas'

export default () => {
  const sagaMiddleware = createSagaMiddleware()

  // sagaMiddleware: Makes redux-sagas work
  const middlewares = [sagaMiddleware]

  const store = configureStore({
    reducer: rootReducer,
    middleware: [...middlewares],
    devTools: process.env.NODE_ENV !== 'production'
  })

  sagaMiddleware.run(sagas)

  return store
}
