export const ENV = {
  RECIPE_BUILDER_API_URL: process.env.REACT_APP_RECIPE_BUILDER_API_URL || '',
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN || '',
  APP_ENV: process.env.REACT_APP_ENV || '',
  SENTRY_RELEASE: process.env.REACT_APP_SENTRY_RELEASE || '',
  WEDDINGS_API_URL: process.env.REACT_APP_WEDDINGS_API_URL || '',
  WEDDINGS_API_KEY: process.env.REACT_APP_WEDDINGS_API_KEY || '',
  NODE_ENV: process.env.NODE_ENV || 'development',
  REACT_APP_USER_BRANCH: process.env.REACT_APP_USER_BRANCH || '',
  AIRTABLE_API_URL: process.env.REACT_APP_AIRTABLE_API_URL || '',
  AIRTABLE_API_KEY: process.env.REACT_APP_AIRTABLE_API_KEY || '',
  DESIGN_GUIDE_URL: process.env.REACT_APP_DESIGN_GUIDE_URL || '',
  NATIVE_PROPOSAL_URL: process.env.REACT_APP_NATIVE_PROPOSAL_URL || '',
  CUSTOMER_PORTAL_URL: process.env.REACT_APP_CUSTOMER_PORTAL_URL || '',
  GOOGLE_MAP_API_KEY: process.env.REACT_APP_GOOGLE_MAP_API_KEY || ''
}
