import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Product } from 'src/common/common.interface'
import { ProposalUnitProductType, TemplateProposalUnitType, UnitCategory } from 'src/common/enum'

export type ModifyStep = 'CATALOG' | 'CHOOSE_PRODUCT_TYPE'
export type CatalogAction = 'ADD_PRODUCT' | 'UPDATE_PRODUCT'

export type TemplateUnit = {
  id?: number
  templateId: number
  unitType: TemplateProposalUnitType | ''
  unitProductType: ProposalUnitProductType | ''
  unitCategory: UnitCategory | ''
}

export type SetSearchParams = {
  key: 'q' | 'cat' | 'color' | 'stem' | 'shape' | 'page' | 'limit' | 'isReview' | 'keystoneTag' | 'isIncludeInReview'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
}

type QueryProduct = {
  loading: boolean
  products?: Product[]
}

export interface ProductCatalogState {
  modifyStep: ModifyStep
  catalogAction: CatalogAction

  searchParam: {
    q?: string
    cat?: string
    color?: string
    stem?: string
    shape?: string
    page?: number
    limit?: number
    isReview?: boolean
    keystoneTag?: string
    isIncludeInReview?: boolean
  }
  loading: boolean

  products: Product[]

  unit: TemplateUnit | null
  selectedProducts: Product[]
}

const initialState: ProductCatalogState = {
  modifyStep: 'CHOOSE_PRODUCT_TYPE',
  catalogAction: 'ADD_PRODUCT',
  searchParam: {
    q: '',
    cat: '',
    color: '',
    stem: '',
    shape: '',
    keystoneTag: '',
    isReview: false,
    page: 1,
    limit: 60
  },
  loading: false,
  products: [],

  unit: null,
  selectedProducts: []
}

const productCatalogSlice = createSlice({
  name: 'product-catalog',
  initialState,
  reducers: {
    setModifyStep: (state, action: PayloadAction<ModifyStep>) => {
      state.modifyStep = action.payload
    },
    queryProductData: (state, action: PayloadAction<QueryProduct>) => {
      state.loading = action.payload.loading
      const { products } = action.payload
      if (products) state.products = state.products.concat(products)
    },
    setCatalogAction: (
      state,
      action: PayloadAction<{ modifyStep: ModifyStep; unit: TemplateUnit | null; catalogAction: CatalogAction }>
    ) => {
      state.modifyStep = action.payload.modifyStep
      state.unit = action.payload.unit
      state.catalogAction = action.payload.catalogAction
    },
    setSearchParams: (state, action: PayloadAction<SetSearchParams>) => {
      const { key, value } = action.payload
      state.searchParam[key] = value
      if (key !== 'page') {
        state.searchParam.page = 1
        state.products = []
      }
    },
    selectProduct: (state, action: PayloadAction<Product>) => {
      const product = action.payload
      const productInSelectedProductIndex = state.selectedProducts?.findIndex((p) => p.id === product.id)
      if (productInSelectedProductIndex === -1) {
        state.selectedProducts.push({ ...product, isSelected: false })
      } else {
        state.selectedProducts.splice(productInSelectedProductIndex, 1)
      }
    },
    clearSearchParams: (state) => {
      state.searchParam = {
        q: '',
        cat: '',
        color: '',
        stem: '',
        shape: '',
        keystoneTag: '',
        isReview: false,
        page: 1,
        limit: 60
      }
      state.selectedProducts = []
      state.products = []
    },
    setTemplateUnitByKey: (
      state,
      action: PayloadAction<{
        key: 'unitType' | 'unitProductType' | 'unitCategory'
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value: any
      }>
    ) => {
      const { key, value } = action.payload
      if (state.unit) state.unit[key] = value
    }
  }
})

export const productCatalogAction = productCatalogSlice.actions

export default productCatalogSlice.reducer
