/* eslint-disable @typescript-eslint/no-use-before-define */
import { takeEvery, all, call, put } from 'redux-saga/effects'
import { createAction } from '@reduxjs/toolkit'
import { appAction } from '../app'
import { getProductsSaga } from '../recipe'
import { getStemsSaga } from '../stem'

function* getOrdersInfoSaga({ payload: isLoadStems }: ReturnType<typeof templateActionSaga.getTemplateInfo>) {
  try {
    yield put(appAction.startLoading())
    if (!isLoadStems) {
      yield all([call(getStemsSaga, { payload: { isAll: true }, type: '' }), call(getProductsSaga)])
    } else {
      yield all([call(getProductsSaga)])
    }
    yield put(appAction.endLoading())
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getOrdersInfoSaga', error)
  }
}

export const templateActionSaga = {
  getTemplateInfo: createAction<boolean>('templateActionSaga/getOrdersInfo')
}

export default [
  function* fetchWatcher() {
    yield all([takeEvery(templateActionSaga.getTemplateInfo, getOrdersInfoSaga)])
  }
]
