import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HardGood, Stem, StemCategory } from 'src/common/common.interface'

export interface ITemplateStem extends Stem {
  quantity: number
  recipeTemplateStemId?: number
}

export interface ITemplateHardGood extends HardGood {
  quantity: number
}

export interface TemplateState {
  stemTemplates: ITemplateStem[]
  stemCategories: StemCategory[]
}

const initialState: TemplateState = {
  stemTemplates: [],
  stemCategories: []
}

const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    setStemTemplate: (state, action: PayloadAction<ITemplateStem[]>) => {
      state.stemTemplates = action.payload
    },

    setStemCategories: (state, action: PayloadAction<StemCategory[]>) => {
      state.stemCategories = action.payload
    }
  }
})

export const templateAction = templateSlice.actions

export default templateSlice.reducer
