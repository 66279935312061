import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'
import {
  NotificationTaskItem,
  FloralManagerItem,
  IGetOverviewProgress,
  NotificationDetailProps,
  SummaryEventDataProps,
  RedirectDataItemProps
} from 'src/common/notificationTask.interface'

export interface NotificationTaskStateInterface {
  // noti list
  notificationTask: NotificationTaskItem[]
  notificationTaskLoading: boolean
  loadingMoreNoti: boolean
  reRenderNotiTask: number
  incompleteNotiCount: number
  loadingIncompleteNotiCount: boolean

  completeNotificationTask: NotificationTaskItem[]
  completeNotificationTaskLoading: boolean
  loadingMoreCompleteNoti: boolean
  hasMoreIncompleteNoti: boolean
  hasMoreCompleteNoti: boolean
  // fm data
  floralManagers: FloralManagerItem[]
  loadingFloralManager: boolean
  // overview progress
  overviewProgress: IGetOverviewProgress
  overviewLoading: boolean
  // noti detail
  summaryEventData: SummaryEventDataProps
  detailTaskList: NotificationDetailProps[]
  reDirectData: RedirectDataItemProps[]
  notiDetailLoading: boolean
  isMarking: boolean
  modalConfirmOpen: boolean
}

const initialState: NotificationTaskStateInterface = {
  // noti task list
  // incomplete and complete
  notificationTask: [],
  notificationTaskLoading: true,
  loadingMoreNoti: false,
  reRenderNotiTask: 0,
  incompleteNotiCount: 0,
  loadingIncompleteNotiCount: true,

  completeNotificationTask: [],
  completeNotificationTaskLoading: true,
  loadingMoreCompleteNoti: false,
  hasMoreIncompleteNoti: true,
  hasMoreCompleteNoti: true,

  floralManagers: [],
  loadingFloralManager: false,
  overviewProgress: {
    totalTask: 0,
    completeTask: 0,
    incompleteTaskInDue: 0,
    incompleteTaskPastDue: 0
  },
  overviewLoading: true,
  summaryEventData: {
    eventName: '',
    eventDate: '',
    eventId: 0,
    fulfillmentOwner: {
      firstName: '',
      lastName: ''
    },
    fulfillmentScheduledDate: null,
    lastCustomerOutreach: null,
    lastOwnerResponse: null
  },
  detailTaskList: [],
  reDirectData: [],
  notiDetailLoading: false,
  isMarking: false,
  modalConfirmOpen: false
}

const notificationTaskSlice = createSlice({
  name: 'notificationTask',
  initialState,
  reducers: {
    // incomplete task list
    setNotificationTaskData(state, action: PayloadAction<NotificationTaskItem[]>) {
      state.notificationTask = action.payload
    },
    setMoreNotificationTaskData(state, action: PayloadAction<NotificationTaskItem[]>) {
      state.notificationTask = state.notificationTask.concat(action.payload)
    },
    setNotificationTaskLoading(state, action: PayloadAction<boolean>) {
      state.notificationTaskLoading = action.payload
    },
    setLoadingMoreNoti(state, action: PayloadAction<boolean>) {
      state.loadingMoreNoti = action.payload
    },
    setIncompleteNotiCount(state, action: PayloadAction<number>) {
      state.incompleteNotiCount = action.payload
    },
    setIncompleteNotiCountLoading(state, action: PayloadAction<boolean>) {
      state.loadingIncompleteNotiCount = action.payload
    },
    // complete task list
    setCompleteNotificationTaskData(state, action: PayloadAction<NotificationTaskItem[]>) {
      state.completeNotificationTask = action.payload
    },
    setMoreCompleteNotificationTaskData(state, action: PayloadAction<NotificationTaskItem[]>) {
      state.completeNotificationTask = state.completeNotificationTask.concat(action.payload)
    },
    setCompleteNotificationTaskLoading(state, action: PayloadAction<boolean>) {
      state.completeNotificationTaskLoading = action.payload
    },
    setLoadingMoreCompleteNoti(state, action: PayloadAction<boolean>) {
      state.loadingMoreCompleteNoti = action.payload
    },
    // has more state to load
    setHasMoreIncompleteNoti(state, action: PayloadAction<boolean>) {
      state.hasMoreIncompleteNoti = action.payload
    },
    setHasMoreCompleteNoti(state, action: PayloadAction<boolean>) {
      state.hasMoreCompleteNoti = action.payload
    },
    setSpecificNotificationTask(state, action: PayloadAction<{ eventId: number; notiId: number }>) {
      const notiLocalData = state.detailTaskList.find((e) => e.id === action.payload.notiId)
      const notiTaskLocalDataIndex = state.notificationTask.findIndex((e) => e.event_id === action.payload.eventId)
      if (notiLocalData && typeof notiTaskLocalDataIndex === 'number') {
        const isOverdue = moment(notiLocalData.dueDate).isBefore(moment())
        const currentFMIncompleteCount = parseInt(
          state.notificationTask[notiTaskLocalDataIndex].fm_native_proposal_incomplete_count
        )
        const currentFMIncompleteOverdueCount = parseInt(
          state.notificationTask[notiTaskLocalDataIndex].fm_native_proposal_incomplete_overdue_count
        )

        if (isOverdue) {
          state.notificationTask[notiTaskLocalDataIndex].fm_native_proposal_incomplete_count = (
            currentFMIncompleteCount - 1
          ).toString()
          state.notificationTask[notiTaskLocalDataIndex].fm_native_proposal_incomplete_overdue_count = (
            currentFMIncompleteOverdueCount - 1
          ).toString()

          state.reRenderNotiTask += 1
        } else {
          state.notificationTask[notiTaskLocalDataIndex].fm_native_proposal_incomplete_count = (
            currentFMIncompleteCount - 1
          ).toString()

          state.reRenderNotiTask += 1
        }
      }
    },
    setFloralManagerData(state, action: PayloadAction<FloralManagerItem[]>) {
      state.floralManagers = action.payload
    },
    setLoadingFloralManager(state, action: PayloadAction<boolean>) {
      state.loadingFloralManager = action.payload
    },
    setOverviewProgressData(state, action: PayloadAction<IGetOverviewProgress>) {
      state.overviewProgress = action.payload
    },
    setOverviewLoading(state, action: PayloadAction<boolean>) {
      state.overviewLoading = action.payload
    },
    setNotificationDetail(state, action: PayloadAction<NotificationDetailProps[]>) {
      state.detailTaskList = action.payload
    },
    setSummaryEventData(state, action: PayloadAction<SummaryEventDataProps>) {
      state.summaryEventData = action.payload
    },
    setRedirectData(state, action: PayloadAction<RedirectDataItemProps[]>) {
      state.reDirectData = action.payload
    },
    setNotiDetailLoading(state, action: PayloadAction<boolean>) {
      state.notiDetailLoading = action.payload
    },
    setIsMarking(state, action: PayloadAction<boolean>) {
      state.isMarking = action.payload
    },
    setModalConfirmOpen(state, action: PayloadAction<boolean>) {
      state.modalConfirmOpen = action.payload
    }
  }
})

export const notificationTaskAction = notificationTaskSlice.actions

export default notificationTaskSlice.reducer
