import { useSelector } from 'react-redux'
import { RootState } from '../reducers'

export const selectorAppLoading = (state: RootState) => state.app.isLoading >= 1
export const selectorAppData = (state: RootState) => state.app
export const selectorAppToast = (state: RootState) => state.app.toast
export const selectorIsDarkTheme = (state: RootState) => state.app.isDarkTheme

export const useAppSelector = () => {
  return {
    app: useSelector(selectorAppData)
  }
}
