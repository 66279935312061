import React from 'react'

import logo from '../../assets/images/logo.png'
import './FullScreenLoading.scss'

export const FullScreenLoading: React.FC = () => {
  return (
    <>
      <div className="full_screen_loading">
        <img src={logo} className="icon-logo" alt="loading" />
      </div>
    </>
  )
}
