import * as Sentry from '@sentry/react'
import { Severity } from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { ENV } from 'src/config/env'

export const SentryInit = () => {
  if (ENV.NODE_ENV === 'production') {
    Sentry.init({
      dsn: ENV.SENTRY_DSN,
      integrations: [
        new Integrations.BrowserTracing(),
        new Sentry.Integrations.Breadcrumbs({
          console: true,
          fetch: true,
          dom: true,
          history: true,
          xhr: true
        })
      ],
      release: ENV.SENTRY_RELEASE,
      environment: ENV.APP_ENV,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0
    })
  }
}

function allStorage() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const archive: any = {} // Notice change here
  const keys = Object.keys(localStorage)
  let i = keys.length

  while (i--) {
    archive[keys[i]] = localStorage.getItem(keys[i])
  }

  return archive
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SentryCaptureAPI = (error: any) => {
  Sentry.captureMessage(`${error?.config?.method} ${error?.config?.url}` ?? 'API', {
    level: Severity.Error,
    contexts: {
      description: {
        ...error,
        localStorage: JSON.stringify(allStorage()),
        description: JSON.stringify(error)
      }
    }
  })
}
