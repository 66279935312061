/* eslint-disable @typescript-eslint/no-use-before-define */
import { createAction } from '@reduxjs/toolkit'
import { takeEvery, all, call, put, debounce } from 'redux-saga/effects'

import { getListRecipeFormulas, IGetListRecipeFormulasPayload, RecipeFormula } from 'src/api/recipeFormula'

import { formulaAction } from './formula.slice'

export function* getRecipeFormulasSaga({ payload }: ReturnType<typeof formulaActionSaga.getListRecipeFormulas>) {
  try {
    const res: { result: RecipeFormula[]; count: number } = yield call(getListRecipeFormulas, payload)

    yield put(formulaAction.setRecipeFormulasData(res.result))
    yield put(formulaAction.setCountRecipeFormulas(res.count))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getRecipeFormulasSaga', error)
  } finally {
    yield put(formulaAction.setIsLoadingGetRecipeFormulas(false))
  }
}

export function* getMoreRecipeFormulasSaga({ payload }: ReturnType<typeof formulaActionSaga.getListRecipeFormulas>) {
  try {
    const res: { result: RecipeFormula[]; count: number } = yield call(getListRecipeFormulas, payload)

    yield put(formulaAction.setMoreRecipeFormulasData(res.result))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getMoreRecipeFormulasSaga', error)
  }
}

export const formulaActionSaga = {
  getListRecipeFormulas: createAction<IGetListRecipeFormulasPayload>('formulaActionSaga/getListRecipeFormulas'),
  getMoreListRecipeFormulas: createAction<IGetListRecipeFormulasPayload>('formulaActionSaga/getMoreListRecipeFormulas')
}

export default [
  function* fetchWatcher() {
    yield all([takeEvery(formulaActionSaga.getMoreListRecipeFormulas, getMoreRecipeFormulasSaga)])
    yield all([debounce(500, formulaActionSaga.getListRecipeFormulas, getRecipeFormulasSaga)])
  }
]
