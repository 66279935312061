import { RecipeStem } from 'src/common/common.interface'
import { postHttp, deleteHttp, putHttp } from './apiLayer'

export interface AddRecipeStemPayload {
  stemId?: number
  quantity: number
  supplierStemId?: number
}

export interface UpdateRecipeStemPayload {
  stemId?: number
  quantity?: number
  supplierStemId?: number
  stemRole?: string
  roleIndex?: number
}

export interface AddRecipeStemPayloadResponse {
  id: number
}

export interface ChangeStemPayload {
  newStemId: number
}

export interface IApplyStemToProductPayload {
  stemId: number
  quantity?: number
  stemRole?: string
  roleIndex?: number
  sequenceNum?: number
}

export interface ApplyEventStemsToAllProductsPayload {
  recipeIds: number[]
  stemsPayload: IApplyStemToProductPayload[]
}

export interface ApplyFormulaStemsToProductsPayload {
  recipeId: number
  stemsPayload: IApplyStemToProductPayload[]
}

export interface ReplaceRecipeStemByNewStemPayload {
  oldStemId: number
  newStemId: number
  recipeIds?: number[]
}

export const addRecipeStem = async (
  recipeId: number,
  payload: AddRecipeStemPayload
): Promise<AddRecipeStemPayloadResponse> => {
  const result = await postHttp(`/recipes/${recipeId}/stems`, payload)
  return result.data
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteRecipeStem = async (recipeId: number, recipeStemId: number): Promise<any> => {
  const result = await deleteHttp(`/recipes/${recipeId}/stems/${recipeStemId}`)
  return result.data
}

export const updateRecipeStem = async (
  recipeId: number,
  recipeStemId: number,
  payload: UpdateRecipeStemPayload
): Promise<AddRecipeStemPayloadResponse> => {
  const result = await putHttp(`/recipes/${recipeId}/stems/${recipeStemId}`, payload)
  return result.data
}

export const changeStem = async (
  recipeId: number,
  stemId: number,
  payload: ChangeStemPayload
): Promise<AddRecipeStemPayloadResponse> => {
  const result = await putHttp(`/recipes/${recipeId}/stem/${stemId}`, payload)
  return result.data
}

export const applyEventStemsToAllProductsAPI = async (
  payload: ApplyEventStemsToAllProductsPayload
): Promise<RecipeStem[]> => {
  const result = await postHttp(`/apply-event-stems-to-all-products`, payload)
  return result.data
}

export const replaceRecipeStemByNewStemAPI = async (
  eventOrderId: number,
  payload: ReplaceRecipeStemByNewStemPayload
): Promise<RecipeStem> => {
  const result = await putHttp(`/eventOrders/${eventOrderId}/replaceRecipeStemByNewStem`, payload)
  return result.data
}

export const applyFormulaStemsToProductsAPI = async (
  payload: ApplyFormulaStemsToProductsPayload[]
): Promise<RecipeStem[]> => {
  const result = await postHttp(`/apply-formula-stems-to-products`, payload)
  return result.data
}
