import { Supplier } from 'src/common/common.interface'
import { getHttp } from './apiLayer'

export const getSupplierForGenericStem = async (): Promise<Supplier[]> => {
  const response = await getHttp(`/suppliers?isAll=true`)
  return response?.data?.result ?? []
}

export const getSupplierWithPreferred = async (): Promise<Supplier[]> => {
  const response = await getHttp(`/suppliers?isAll=true&preferred=true`)
  return response?.data?.result ?? []
}
