/* eslint-disable @typescript-eslint/no-use-before-define */
import { createAction } from '@reduxjs/toolkit'
import { takeEvery, all, call, put, debounce } from 'redux-saga/effects'

import { getStemCategories } from 'src/api/stemCategory'
import { getListStems, IGetStemPayload } from 'src/api/stem'
import { StemCategory, Stem, Supplier, SupplierStem } from 'src/common/common.interface'
import { getSupplierForGenericStem, getSupplierWithPreferred } from 'src/api/supplier'
import { getListSupplierStems, ListSupplierStemsParams } from 'src/api/supplierStem'

import { stemAction } from './stem.slice'
import { appAction } from '../app'

export function* getStemCategoriesSaga() {
  try {
    const stemCategories: StemCategory[] = yield call(getStemCategories)

    yield put(stemAction.setStemCategoriesData(stemCategories))
  } catch (error) {
    yield put(appAction.setErrorPage(false))
    // eslint-disable-next-line no-console
    console.error('error getStemCategoriesSaga', error)
  }
}
export function* getStemsSaga({ payload }: ReturnType<typeof stemActionSaga.getListStems>) {
  try {
    const stems: { result: Stem[]; count: number } = yield call(getListStems, payload)

    yield put(stemAction.setStemsData(stems.result))
    yield put(stemAction.setCountStem(stems.count))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getStemsSaga', error)
  } finally {
    yield put(stemAction.setIsLoadingGetStems(false))
  }
}

export function* getMoreStemsSaga({ payload }: ReturnType<typeof stemActionSaga.getListStems>) {
  try {
    const stems: { result: Stem[]; count: number } = yield call(getListStems, payload)

    yield put(stemAction.setMoreStemsData(stems.result))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getMoreStemsSaga', error)
  }
}

export function* listAllSupplier() {
  try {
    const suppliers: Supplier[] = yield call(getSupplierForGenericStem)

    yield put(stemAction.setSuppliers(suppliers))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error listAllSupplier', error)
  }
}

export function* listSupplierStemsSaga({ payload }: ReturnType<typeof stemActionSaga.listSupplierStems>) {
  try {
    const supplierStems: { result: SupplierStem[]; count: number } = yield call(getListSupplierStems, payload)

    yield put(stemAction.setSupplierStems(supplierStems.result))
  } catch (error) {
    yield put(appAction.setErrorPage(false))
    // eslint-disable-next-line no-console
    console.error('error listSupplierStemsSaga', error)
  }
}

export function* listAllSupplierWithPreferredSaga() {
  try {
    const preferredSuppliers: Supplier[] = yield call(getSupplierWithPreferred)

    yield put(stemAction.setPreferredSuppliers(preferredSuppliers))
  } catch (error) {
    yield put(appAction.setErrorPage(false))
    // eslint-disable-next-line no-console
    console.error('error listAllSupplierWithPreferredSaga', error)
  }
}

export const stemActionSaga = {
  getStemCategories: createAction('stemActionSaga/getStemCategories'),
  getListStems: createAction<IGetStemPayload>('stemActionSaga/getListStems'),
  getMoreListStems: createAction<IGetStemPayload>('stemActionSaga/getMoreListStems'),
  listAllSupplier: createAction('stemActionSaga/listAllSupplier'),
  listSupplierStems: createAction<ListSupplierStemsParams>('stemActionSaga/listSupplierStems'),
  listAllSupplierWithPreferred: createAction('stemActionSaga/listAllSupplierWithPreferred')
}

export default [
  function* fetchWatcher() {
    yield all([
      takeEvery(stemActionSaga.getStemCategories, getStemCategoriesSaga),
      takeEvery(stemActionSaga.getMoreListStems, getMoreStemsSaga),
      takeEvery(stemActionSaga.listAllSupplier, listAllSupplier),
      takeEvery(stemActionSaga.listSupplierStems, listSupplierStemsSaga),
      takeEvery(stemActionSaga.listAllSupplierWithPreferred, listAllSupplierWithPreferredSaga)
    ])
    yield all([debounce(500, stemActionSaga.getListStems, getStemsSaga)])
  }
]
