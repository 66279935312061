import { PhotosNeeded } from 'src/common/enum'
import { getHttp } from './apiLayer'
import { PRODUCT_DETAIL_TYPE } from './products'

export interface ProductType {
  id?: number
  name: string
  key: PRODUCT_DETAIL_TYPE | null
  sop: string | null
  defaultPublicNotes: string | null
  defaultPrepTimeMinutes: number | null
  defaultDesignTimeMinutes: number | null
  defaultInstallTimeMinutes: number | null
  defaultLoadingTimeMinutes: number | null
  defaultInstructions: string | null
  defaultPhotosNeeded: PhotosNeeded[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultHardGoods: any
}

export const getAllProductTypes = async (): Promise<ProductType[]> => {
  const result = await getHttp(`getProductTypes`)
  return result.data
}
