import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StemCategory, Stem, Supplier, SupplierStem } from 'src/common/common.interface'
import { GENERIC_SUPPLIER } from 'src/common/constants'

export interface StemState {
  stemCategories?: StemCategory[]
  stems: Stem[]
  countStem: number
  isLoadingGetStems: boolean
  suppliers: Supplier[]
  supplierStems: SupplierStem[]
  preferredSuppliers?: Supplier[]
}

const initialState: StemState = {
  stemCategories: undefined,
  stems: [],
  countStem: 0,
  supplierStems: [],
  isLoadingGetStems: false,
  suppliers: [],
  preferredSuppliers: undefined
}

const stemSlice = createSlice({
  name: 'stem',
  initialState,
  reducers: {
    setStemCategoriesData(state, action: PayloadAction<StemCategory[]>) {
      state.stemCategories = action.payload
    },
    addMoreStemCategory(state, action: PayloadAction<StemCategory>) {
      if (action.payload) state.stemCategories?.push(action.payload)
    },
    setStemsData(state, action: PayloadAction<Stem[]>) {
      action.payload?.forEach((stem) => {
        stem.supplier = GENERIC_SUPPLIER
      })
      state.stems = action.payload
    },
    setMoreStemsData(state, action: PayloadAction<Stem[]>) {
      action.payload?.forEach((stem) => {
        stem.supplier = GENERIC_SUPPLIER
      })
      state.stems = state.stems.concat(action.payload)
    },
    removeStemFromList(state, action: PayloadAction<Stem>) {
      const stemsTemp = [...state.stems]
      const indexStem = stemsTemp.findIndex((s) => s.id === action.payload.id)
      if (indexStem > -1) {
        state.stems.splice(indexStem, 1)
      }
    },
    setCountStem(state, action: PayloadAction<number>) {
      state.countStem = action.payload
    },
    setIsLoadingGetStems(state, action: PayloadAction<boolean>) {
      state.isLoadingGetStems = action.payload
    },
    add1StemData(state, action: PayloadAction<Stem>) {
      state.stems.unshift({ ...action.payload, supplier: GENERIC_SUPPLIER })
    },
    update1StemData(state, action: PayloadAction<Stem>) {
      const stems = [...state.stems]
      const index = stems.findIndex((s) => s.id === action.payload.id)
      if (index > -1) {
        stems[index] = action.payload
        state.stems = [...stems]
      }
    },
    delete1StemData(state, action: PayloadAction<Stem>) {
      const stems = [...state.stems]
      const index = stems.findIndex((s) => s.id === action.payload.id)
      if (index > -1) {
        stems.splice(index, 1)
        state.stems = [...stems]
      }
    },
    setSuppliers(state, action: PayloadAction<Supplier[]>) {
      state.suppliers = action.payload
    },
    setSupplierStems(state, action: PayloadAction<SupplierStem[]>) {
      state.supplierStems = action.payload
    },
    addSupplierStemsData(state, action: PayloadAction<SupplierStem[]>) {
      let supplierStemsTemp = [...state.supplierStems]
      supplierStemsTemp = supplierStemsTemp.concat(action.payload)
      state.supplierStems = [...supplierStemsTemp]
    },
    update1SupplierStemData(state, action: PayloadAction<SupplierStem>) {
      const supplierStemsTemp = [...state.supplierStems]
      const index = supplierStemsTemp.findIndex((s) => s.id === action.payload.id)
      if (index > -1) {
        supplierStemsTemp[index] = action.payload
        state.supplierStems = [...supplierStemsTemp]
      }
    },
    setPreferredSuppliers(state, action: PayloadAction<Supplier[]>) {
      state.preferredSuppliers = action.payload
    }
  }
})

export const stemAction = stemSlice.actions

export default stemSlice.reducer
