import { RecipeFormulaSize, RecipeFormulaStemRole, RecipeFormulaStyle } from './enum'

export const GENERIC_SUPPLIER = 'Generic'
export const PAGE_LIMIT = 20
export const LIMIT_FILE_UPLOAD_SIZE = 4194304

export enum HttpCustomResponseCode {
  DUPLICATE_PRODUCT_RECIPE_TEMPLATE = 'DUPLICATE_PRODUCT_RECIPE_TEMPLATE'
}

export const STEM_TOTAL_WARNING_PERCENT = 15
export const HARDGOOD_TOTAL_WARNING_PERCENT = 6
export const FREIGHT_TOTAL_WARNING_PERCENT = 6
export const COMBINED_TOTAL_WARNING_PERCENT = 27

export const ACCEPTED_IMAGE_FORMATS = ['.png', '.jpg', '.jpeg', '.heic', '.heif']
export const ACCEPTED_PDF_FORMATS = ['.pdf']

export const CAN_NOT_CREATE_SHIP_STATION_ORDER = 'CAN_NOT_CREATE_SHIP_STATION_ORDER'

export const styleArray = [
  {
    value: RecipeFormulaStyle.CLASSIC,
    label: 'Classic'
  },
  {
    value: RecipeFormulaStyle.LUSH,
    label: 'Lush'
  },
  {
    value: RecipeFormulaStyle.WILDFLOWER,
    label: 'Wildflower'
  },
  {
    value: RecipeFormulaStyle.ASYMMETRICAL,
    label: 'Asymmetrical'
  }
]

export const sizeArray = [
  {
    value: RecipeFormulaSize.SMALL,
    label: 'Small'
  },
  {
    value: RecipeFormulaSize.MEDIUM,
    label: 'Medium'
  },
  {
    value: RecipeFormulaSize.LARGE,
    label: 'Large'
  }
]

export const colorComplexityArray = [
  {
    value: 1,
    label: '1 Color'
  },
  {
    value: 2,
    label: '2 Colors'
  },
  {
    value: 3,
    label: '3 Colors'
  },
  {
    value: 4,
    label: '4 Colors'
  },
  {
    value: 5,
    label: '5 Colors'
  },
  {
    value: 6,
    label: '6 Colors'
  }
]

export const stemRoleArray = [
  {
    value: RecipeFormulaStemRole.FOCAL,
    label: 'Focal'
  },
  {
    value: RecipeFormulaStemRole.SECONDARY,
    label: 'Secondary'
  },
  {
    value: RecipeFormulaStemRole.FILLER,
    label: 'Filler'
  },
  {
    value: RecipeFormulaStemRole.LINEAR,
    label: 'Linear'
  },
  {
    value: RecipeFormulaStemRole.GREENERY,
    label: 'Greenery'
  },
  {
    value: RecipeFormulaStemRole.SPECIALTY,
    label: 'Specialty'
  }
]
