import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ColorPalette, ProposalTemplate, TemplateProduct, TemplateProductPayload } from 'src/common/common.interface'
import { templateProductToProductPayload } from 'src/pages/TemplateProduct/helper'

export interface ProposalTemplateState {
  templates: ProposalTemplate[]
  templateProducts: TemplateProduct[]
  palettes: ColorPalette[]

  currentTemplate: ProposalTemplate | null
  templateVersion: 'V1' | 'V2'

  openProductCatalog: boolean

  productPayload: TemplateProductPayload
}

const initialState: ProposalTemplateState = {
  templates: [],
  templateProducts: [],
  currentTemplate: null,
  templateVersion: 'V1',
  palettes: [],
  openProductCatalog: false,
  productPayload: { products: [] }
}

const proposalTemplateSlice = createSlice({
  name: 'proposal-template',
  initialState,
  reducers: {
    setProposalTemplates: (state, action: PayloadAction<ProposalTemplate[]>) => {
      state.templates = action.payload
    },
    setTemplateProducts: (state, action: PayloadAction<TemplateProduct[]>) => {
      const templateProducts = action.payload || []
      templateProducts.forEach((tp) => {
        tp.originalIndex = tp.sequenceIndex
      })
      state.templateProducts = templateProducts
    },
    updateTemplateProductsIndex: (state, action: PayloadAction<TemplateProduct[]>) => {
      action.payload.forEach((tp) => {
        const index = state.templateProducts.findIndex((p) => p.id === tp.id)
        if (index > -1) {
          state.templateProducts[index].sequenceIndex = tp.sequenceIndex
        }

        // Update payload
        const payloadIndex = state.productPayload.products.findIndex((p) => p.id === tp.id)
        if (payloadIndex > -1) {
          state.productPayload.products[payloadIndex].sequenceIndex = tp.sequenceIndex
        } else {
          state.productPayload.products.push(templateProductToProductPayload(tp, { isSequenceIndexChanged: tp.id > 0 }))
        }
      })
    },
    setProposalTemplate: (state, action: PayloadAction<ProposalTemplate | null>) => {
      state.currentTemplate = action.payload
    },
    setTemplateVersion: (state, action: PayloadAction<'V1' | 'V2'>) => {
      state.templateVersion = action.payload
    },
    setPalettes: (state, action: PayloadAction<ColorPalette[]>) => {
      state.palettes = action.payload
    },
    changeTemplatePalette: (state, action: PayloadAction<ColorPalette>) => {
      if (state.currentTemplate) {
        state.currentTemplate.colorPalette = action.payload
      }
    },
    removeATemplateProduct: (state, action: PayloadAction<number>) => {
      const tempProductId = action.payload
      const index = state.templateProducts.findIndex((tp) => tp.id === tempProductId)
      let oldProduct: TemplateProduct | undefined
      if (index > -1) {
        oldProduct = state.templateProducts[index]
        state.templateProducts.splice(index, 1)
      }

      if (oldProduct) {
        if (tempProductId > 0) {
          const payloadIndex = state.productPayload.products.findIndex((p) => p.id === tempProductId)
          if (payloadIndex > -1) {
            state.productPayload.products[payloadIndex].isDeleted = true
            state.productPayload.products[payloadIndex].isSequenceIndexChanged = false
          } else {
            state.productPayload.products.push({
              id: tempProductId,
              templateId: oldProduct.templateId,
              unitType: oldProduct.unitType,
              productType: oldProduct.productType,
              unitProductType: oldProduct.unitProductType,
              proposalUnitCategory: oldProduct.proposalUnitCategory,
              productId: oldProduct.product.id,
              isDeleted: true
            })
          }
        } else {
          const rIndex = state.productPayload.products.findIndex((p) => p.id === oldProduct?.id)
          if (rIndex > -1) state.productPayload.products.splice(rIndex, 1)
        }
      }
    },
    setOpenProductCatalog: (state, action: PayloadAction<boolean>) => {
      state.openProductCatalog = action.payload
    },
    setProductPayload: (state, action: PayloadAction<TemplateProductPayload>) => {
      state.productPayload.paletteId = action.payload.paletteId
      action.payload.products.forEach((p) => {
        const index = state.productPayload.products.findIndex((tp) => tp.id === p.id)
        if (index > -1) {
          state.productPayload.products[index] = { ...p }
        } else {
          state.productPayload.products.push(p)
        }
      })
    },
    resetAfterSaveChange: (state, action: PayloadAction<{ oldId?: number; newId: number }[]>) => {
      state.productPayload = { products: [] }
      action.payload.forEach((p) => {
        const index = state.templateProducts.findIndex((tp) => tp.id === p.oldId)
        if (index > -1) {
          state.templateProducts[index].id = p.newId
        }
      })
      state.templateProducts = state.templateProducts.map((p) => ({
        ...p,
        isPreview: false,
        originalIndex: p.sequenceIndex
      }))
    },
    addSelectedProductsToTemplate: (
      state,
      action: PayloadAction<{ catalogAction: 'ADD_PRODUCT' | 'UPDATE_PRODUCT'; products: TemplateProduct[] }>
    ) => {
      const { products, catalogAction } = action.payload

      if (!state.productPayload) state.productPayload = { products: [] }

      if (catalogAction === 'ADD_PRODUCT') {
        state.templateProducts.push(...products.map((p) => ({ ...p, isPreview: true })))
        state.productPayload.products.push(
          ...products.map((p) => ({
            id: p.id,
            templateId: p.templateId,
            unitType: p.unitType,
            productType: p.productType,
            unitProductType: p.unitProductType,
            proposalUnitCategory: p.proposalUnitCategory,
            productId: p.product.id,
            isSelected: false
          }))
        )
      }
      if (catalogAction === 'UPDATE_PRODUCT') {
        products.forEach((p) => {
          const index = state.templateProducts.findIndex((tp) => tp.id === p.id)
          if (index >= 0) state.templateProducts[index] = { ...p, isPreview: true }

          const uIndex = state.productPayload.products.findIndex((pp) => pp.id === p.id)
          if (uIndex >= 0)
            state.productPayload.products[uIndex] = {
              id: p.id,
              templateId: p.templateId,
              unitType: p.unitType,
              productType: p.productType,
              unitProductType: p.unitProductType,
              proposalUnitCategory: p.proposalUnitCategory,
              productId: p.product.id,
              isSelected: false
            }
          else
            state.productPayload.products.push({
              id: p.id,
              templateId: p.templateId,
              unitType: p.unitType,
              productType: p.productType,
              unitProductType: p.unitProductType,
              proposalUnitCategory: p.proposalUnitCategory,
              productId: p.product.id,
              isSelected: false
            })
        })
      }
    },
    toggleTemplateProductIsSelected: (
      state,
      action: PayloadAction<{ templateProductId: number; isSelected: boolean }>
    ) => {
      const { templateProductId, isSelected } = action.payload
      const existingProduct = state.templateProducts.find((tp) => tp.id === templateProductId)
      const existingSelected = state.templateProducts.find(
        (tp) => tp.isSelected && tp.id !== templateProductId && tp.unitType === existingProduct?.unitType
      )
      if (isSelected) {
        // Clear all selected in the same unit type
        state.templateProducts
          .filter((tp) => tp.unitType === existingProduct?.unitType)
          .forEach((tp) => {
            tp.isSelected = false
          })
      }
      if (existingProduct) {
        existingProduct.isSelected = isSelected
        // Update payload
        const payloadIndex = state.productPayload.products.findIndex((p) => p.id === templateProductId)
        if (payloadIndex > -1) {
          state.productPayload.products[payloadIndex].isSelected = isSelected
        } else {
          state.productPayload.products.push(templateProductToProductPayload(existingProduct))
        }
      }
      if (existingSelected) {
        existingSelected.isSelected = false
        // Update payload
        const payloadIndex = state.productPayload.products.findIndex((p) => p.id === existingSelected.id)
        if (payloadIndex > -1) {
          state.productPayload.products[payloadIndex].isSelected = false
        } else {
          state.productPayload.products.push(templateProductToProductPayload(existingSelected))
        }
      }
    }
  }
})

export const proposalTemplateAction = proposalTemplateSlice.actions

export default proposalTemplateSlice.reducer
