import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import { appAction, selectorAppToast } from 'src/store/app'
import { useDispatch, useSelector } from 'react-redux'

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export const SnackBarBase = () => {
  const toast = useSelector(selectorAppToast)
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(appAction.setToast(undefined))
  }

  return (
    <Snackbar open={!!toast} autoHideDuration={2500} onClose={handleClose}>
      <Alert onClose={handleClose} severity={toast?.color}>
        {toast?.message}
      </Alert>
    </Snackbar>
  )
}
