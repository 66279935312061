import { RecipeHardGood } from 'src/common/common.interface'
import { postHttp, deleteHttp, putHttp } from './apiLayer'

export interface AddRecipeHardGoodPayload {
  hardGoodId: number
  quantity: number
  quantityType: string
}

export const addRecipeHardGoodApi = async (
  recipeId: number,
  payloads: AddRecipeHardGoodPayload[]
): Promise<RecipeHardGood> => {
  const result = await postHttp(`/recipes/${recipeId}/hard-goods`, payloads)
  return result.data
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteRecipeHardGoodApi = async (recipeId: number, recipeHardGoodId: number): Promise<any> => {
  const result = await deleteHttp(`/recipes/${recipeId}/hard-goods/${recipeHardGoodId}`)
  return result.data
}

export const updateRecipeHardGoodApi = async (
  recipeId: number,
  recipeHardGoodId: number,
  payload: AddRecipeHardGoodPayload
): Promise<RecipeHardGood> => {
  const result = await putHttp(`/recipes/${recipeId}/hard-goods/${recipeHardGoodId}`, payload)
  return result.data
}
