import { StemCategory } from '../common/common.interface'
import { handleGetAllData } from './helper'

export interface SupplierStemsResponse {
  count: number
  result: StemCategory[]
}

export const getStemCategories = async () => {
  const data = await handleGetAllData<StemCategory>('stems/categories')
  return data
}
