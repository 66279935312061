import React, { useState, useEffect, useRef } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Box from '@material-ui/core/Box'
import CheckBoxIcon from 'src/assets/icons/checkbox-icon.png'
import CheckedBoxIcon from 'src/assets/icons/checkedbox-icon.png'
import { ReactComponent as DropdownIcon } from 'src/assets/icons/dropdow-arrow.svg'

import { useDebounce } from 'src/shared/hook/useDebounce'

import {
  MenuItem,
  Select,
  SelectProps,
  TextField,
  TextFieldProps,
  TextareaAutosize,
  TextareaAutosizeProps,
  InputAdornment,
  FormControl,
  InputLabel,
  Checkbox,
  InputProps
} from '@material-ui/core'
import './FormStyles.scss'
import { Autocomplete, UseAutocompleteProps } from '@material-ui/lab'

export interface SelectOption {
  value: string | number
  label: string
  id?: string | number
}

export type FormInputProps2 = TextFieldProps & {
  helperText?: string
  errorText?: string
  className?: string
}

type FormTextAreaProps = TextareaAutosizeProps & {
  helperText?: string
  errorText?: string
  className?: string
  error?: boolean
}

export type FormSelectProps = SelectProps & {
  helperText?: string
  errorText?: string
  className?: string
  options?: SelectOption[]
}

export type FormAutoCompleteProps = UseAutocompleteProps<string | SelectOption, boolean, undefined, undefined> & {
  multiple?: boolean
  disableclear?: string
  isdisabled?: string
  options?: (string | SelectOption)[]
  onChange?: (data: string | SelectOption | (string | SelectOption)[] | null) => void
  label?: string
  isCheckBox?: boolean
  helperText?: string
  errorText?: string
  className?: string
  customgetoptionlabel?: (option: SelectOption | string) => string
  InputProps?: Partial<InputProps>
}

const useStyles = makeStyles({
  formInputRoot: {
    position: 'relative',
    padding: '8px 16px',
    height: '56px',
    border: '1px solid #BDBCBC',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    '&:focus-within, &.filled': {
      '& $floatingLabel': {
        transform: 'translate(0, -16px) scale(0.75)'
      }
    },
    '&.disabled': {
      cursor: 'not-allowed',
      opacity: '0.7',
      '& $floatingLabel': {
        cursor: 'not-allowed'
      }
    },
    '&.placeholder': {
      alignItems: 'flex-end'
    },
    '&.small': {
      height: '37px'
    }
  },
  floatingLabel: {
    position: 'absolute',
    fontFamily: 'Brandon Grotesque',
    fontSize: '16px',
    fontWeight: 390,
    lineHeight: '24px',
    color: '#787475 !important',
    transition: '200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    transform: 'translate(0, -6px) scale(1)',
    transformOrigin: 'top left',
    zIndex: 0
  },
  inputRoot: {
    // zIndex: 1
  },
  input: {
    backgroundColor: 'transparent !important',
    cursor: 'not-allowed',
    '&:before': {
      display: 'none'
    },
    '&:after': {
      display: 'none'
    },
    '&.Mui-disabled input': {
      backgroundColor: 'transparent !important',
      cursor: 'not-allowed'
    }
  },
  loading: {
    '&:after': {
      content: "' '",
      position: 'absolute',
      width: '16px',
      height: '16px',
      margin: 'auto 8px auto auto',
      border: '4px solid transparent',
      'border-top-color': '#f97',
      'border-radius': '50%',
      animation: 'button-loading-spinner 1s ease infinite',
      inset: 0
    }
  }
})

type FormInputProps = TextFieldProps & {
  className?: string
  debounce?: number
  onChangeValue?: (value: string) => void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FormInput: React.FC<FormInputProps> = React.forwardRef((props: FormInputProps, ref: any) => {
  const { className = '', onChangeValue, value, prefix, debounce = 500, size, ...rest } = props
  const classes = useStyles()
  const [inputValue, setInputValue] = useState<string>(value as string)
  const { placeholder } = rest
  delete rest.placeholder
  const inputRef = useRef<HTMLInputElement>()

  const debouncedValue = useDebounce<string>(inputValue, debounce)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  const handleBlur = () => {
    if (inputValue === '') {
      onChangeValue?.(inputValue)
    }
  }

  useEffect(() => {
    if (debouncedValue !== value) {
      if (debouncedValue !== '') {
        onChangeValue?.(debouncedValue)
      }
    }
  }, [debouncedValue])

  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value as string)
    }
  }, [value])

  return (
    <Box
      // eslint-disable-next-line prettier/prettier
      className={`${classes.formInputRoot} ${className} ${!!prefix || !!inputValue ? 'filled' : ''} ${rest.disabled ? 'disabled' : ''
        // eslint-disable-next-line prettier/prettier
        } ${placeholder ? 'placeholder' : ''} ${size ?? ''}`}
      onClick={() => inputRef.current?.focus()}
    >
      {placeholder && <span className={classes.floatingLabel}>{placeholder}</span>}
      <TextField
        ref={ref}
        className={classes.inputRoot}
        fullWidth
        value={inputValue}
        onChange={handleChange}
        InputProps={{
          className: classes.input,
          startAdornment: prefix ? <InputAdornment position="start">{prefix}</InputAdornment> : <></>
        }}
        inputRef={inputRef}
        {...rest}
        onBlur={handleBlur}
      />
    </Box>
  )
})
FormInput.displayName = 'FormInput'

export default FormInput

export const FormInput2 = React.forwardRef<HTMLDivElement, FormInputProps2>((props: FormInputProps2, ref) => {
  return (
    <div className={`form-group ${props.className || ''}`} ref={ref}>
      <div className="form-input">
        <TextField {...props} />
      </div>
      {props.helperText ? <p className="helper-text">{props.helperText}</p> : <></>}
      {props.error && props.errorText && <p className="error-text">{props.errorText}</p>}
    </div>
  )
})
FormInput2.displayName = 'FormInput2'

export const FormTextArea = ({ error, className, helperText, errorText, ...rest }: FormTextAreaProps) => {
  return (
    <div className={`form-group ${className || ''}`}>
      <div className="form-input">
        <TextareaAutosize className="textarea" {...rest} />
      </div>
      {helperText ? <p className="helper-text">{helperText}</p> : <></>}
      {error && <p className="error-text">{errorText}</p>}
    </div>
  )
}

export const FormSelect = React.forwardRef<HTMLDivElement, FormSelectProps>((props: FormSelectProps, ref) => {
  return (
    <div className={`form-group ${props.className || ''}`} ref={ref}>
      <div className="form-input">
        <FormControl>
          <InputLabel id="customized-select-label">{props.label}</InputLabel>
          <Select labelId="customized-select-label" {...props}>
            {props.options?.map((option) => {
              return (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>
      {props.helperText ? <p className="helper-text">{props.helperText}</p> : <></>}
      {props.error && <p className="error-text">{props.errorText}</p>}
    </div>
  )
})
FormSelect.displayName = 'FormSelect'

export const FormAutoComplete = React.forwardRef<HTMLDivElement, FormAutoCompleteProps>(
  (props: FormAutoCompleteProps, ref) => {
    const newProps = { ...props, disableclear: undefined, isdisabled: undefined, customgetoptionlabel: undefined }
    return (
      <div className={`form-group ${props.className || ''}`} ref={ref}>
        <div className="form-input">
          <Autocomplete
            {...newProps}
            multiple={props.multiple || false}
            disableClearable={!!props.disableclear}
            disabled={!!props.isdisabled}
            popupIcon={<DropdownIcon />}
            className="form-select"
            options={props.options || []}
            getOptionLabel={(option: SelectOption | string) =>
              props.customgetoptionlabel
                ? props.customgetoptionlabel(option)
                : typeof option === 'string'
                ? option
                : option.label
            }
            renderOption={(option: SelectOption | string, { selected }) => (
              <>
                {props.isCheckBox && (
                  <Checkbox
                    icon={<img src={CheckBoxIcon} alt="" />}
                    checkedIcon={<img src={CheckedBoxIcon} alt="" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                )}
                {typeof option === 'string' ? option : option.label}
              </>
            )}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  InputProps={
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (params.inputProps as any).value
                      ? { ...params.InputProps, ...props.InputProps }
                      : { ...params.InputProps }
                  }
                  label={props.label}
                  variant="outlined"
                />
              )
            }}
            onChange={(_, data) => props.onChange && props.onChange(data)}
          />
        </div>
        {props.helperText ? <p className="helper-text">{props.helperText}</p> : <></>}
        {props.errorText && <p className="error-text">{props.errorText}</p>}
      </div>
    )
  }
)
FormAutoComplete.displayName = 'FormAutoComplete'
