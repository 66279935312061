/* eslint-disable @typescript-eslint/no-use-before-define */
import { takeEvery, all, call, put } from 'redux-saga/effects'
import { createAction } from '@reduxjs/toolkit'
import {
  getListProposalTemplates,
  getProposalTemplate,
  getTemplateProducts,
  getListColorPalettes,
  updateProposalTemplate
} from 'src/api/templateProduct'
import {
  ColorPalette,
  Pagination,
  PaginationParams,
  ProposalTemplate,
  TemplateProduct,
  TemplateProductPayload
} from 'src/common/common.interface'
import { appAction } from '../app'
import { proposalTemplateAction } from './template.slide'

function* callGetProposalTemplates({ payload }: ReturnType<typeof proposalTemplateActionSaga.getProposalTemplates>) {
  try {
    yield put(appAction.startLoading())
    const response: Pagination<ProposalTemplate[]> = yield call(getListProposalTemplates, payload)
    yield put(proposalTemplateAction.setProposalTemplates(response.results))
    yield put(appAction.endLoading())
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getProposalTemplates', error)
  }
}

function* callGetColorPalettes({ payload }: ReturnType<typeof proposalTemplateActionSaga.getListColorPalettes>) {
  try {
    yield put(appAction.startLoading())
    const response: Pagination<ColorPalette[]> = yield call(getListColorPalettes, payload)
    yield put(proposalTemplateAction.setPalettes(response.results))
    yield put(appAction.endLoading())
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getProposalTemplates', error)
  }
}

function* callGetTemplateProducts({ payload }: ReturnType<typeof proposalTemplateActionSaga.getTemplateProducts>) {
  try {
    yield put(appAction.startLoading())
    const response: Pagination<TemplateProduct[]> = yield call(getTemplateProducts, payload)
    yield put(proposalTemplateAction.setTemplateProducts(response.results))
    yield put(appAction.endLoading())
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getProposalTemplates', error)
  }
}

function* callGetProposalTemplate({ payload }: ReturnType<typeof proposalTemplateActionSaga.getTemplateProducts>) {
  try {
    yield put(appAction.startLoading())
    const response: ProposalTemplate = yield call(getProposalTemplate, payload)
    yield put(proposalTemplateAction.setProposalTemplate(response))
    yield put(appAction.endLoading())
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getProposalTemplates', error)
  }
}

function* callUpdateProposalTemplate({
  payload
}: ReturnType<typeof proposalTemplateActionSaga.updateProposalTemplate>) {
  try {
    yield put(appAction.startLoading())
    const result: { oldId?: number; newId: number }[] = yield call(
      updateProposalTemplate,
      payload.templateId,
      payload.data
    )
    yield put(proposalTemplateAction.resetAfterSaveChange(result))
    yield put(appAction.endLoading())
    yield put(
      appAction.setToast({
        color: 'success',
        message: 'Save proposal template successfully'
      })
    )
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getProposalTemplates', error)
  } finally {
    yield put(appAction.endLoading())
  }
}

function* callUpdateTemplateColorPalette({
  payload
}: ReturnType<typeof proposalTemplateActionSaga.updateTemplateColorPalette>) {
  try {
    yield put(appAction.startLoading())
    yield call(updateProposalTemplate, payload.templateId, { paletteId: payload.data.id, products: [] })
    yield put(proposalTemplateAction.changeTemplatePalette(payload.data))
    yield put(appAction.endLoading())
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getProposalTemplates', error)
  }
}

export const proposalTemplateActionSaga = {
  getProposalTemplates: createAction<PaginationParams>('proposalTemplateActionSaga/getProposalTemplates'),
  getTemplateProducts: createAction<number>('proposalTemplateActionSaga/getTemplateProducts'),
  getProposalTemplate: createAction<number>('proposalTemplateActionSaga/getProposalTemplate'),
  getListColorPalettes: createAction<PaginationParams>('proposalTemplateActionSaga/getListColorPalettes'),
  updateProposalTemplate: createAction<{ templateId: number; data: TemplateProductPayload }>(
    'proposalTemplateActionSaga/updateProposalTemplate'
  ),
  updateTemplateColorPalette: createAction<{ templateId: number; data: ColorPalette }>(
    'proposalTemplateActionSaga/updateTemplateColorPalette'
  )
}

export default [
  function* fetchWatcher() {
    yield all([
      takeEvery(proposalTemplateActionSaga.getProposalTemplates, callGetProposalTemplates),
      takeEvery(proposalTemplateActionSaga.getListColorPalettes, callGetColorPalettes),
      takeEvery(proposalTemplateActionSaga.getTemplateProducts, callGetTemplateProducts),
      takeEvery(proposalTemplateActionSaga.getProposalTemplate, callGetProposalTemplate),
      takeEvery(proposalTemplateActionSaga.updateProposalTemplate, callUpdateProposalTemplate),
      takeEvery(proposalTemplateActionSaga.updateTemplateColorPalette, callUpdateTemplateColorPalette)
    ])
  }
]
