import { ProductPayload, TemplateProduct } from 'src/common/common.interface'
import { EventCategory, ProposalUnitProductType, TemplateProposalUnitType, UnitCategory } from 'src/common/enum'

export const UNIT_TYPE_V1 = [
  TemplateProposalUnitType.BRIDAL_BOUQUET,
  TemplateProposalUnitType.GROOM_BOUTS,
  TemplateProposalUnitType.BRIDESMAIDS_BOUQUET,
  TemplateProposalUnitType.BOUTS,
  TemplateProposalUnitType.CORSAGE,
  TemplateProposalUnitType.BRIDAL_HAIR_PIECE,
  TemplateProposalUnitType.FLOWER_GIRL_CROWN,
  TemplateProposalUnitType.WELCOME_SIGN_DECOR,
  TemplateProposalUnitType.CEREMONY_DECOR,
  TemplateProposalUnitType.AISLE_DECOR,
  TemplateProposalUnitType.COCKTAIL_DECOR,
  TemplateProposalUnitType.MANTLE_DECOR,
  TemplateProposalUnitType.ROUND_TABLE_DECOR,
  TemplateProposalUnitType.FARM_TABLE_DECOR,
  TemplateProposalUnitType.CAKE_FLOWER,
  TemplateProposalUnitType.SWEETHEART_TABLE_DECOR,
  TemplateProposalUnitType.SERVICE_LEVELS,
  TemplateProposalUnitType.EXTRA_FEES
]
export const UNIT_CATEGORY_V2 = [
  UnitCategory.COUPLES_FLOWERS,
  UnitCategory.WEDDING_PARTY,
  UnitCategory.OTHER_PERSONALS,
  UnitCategory.AISLE_DECOR,
  UnitCategory.ALTAR_DECOR,
  UnitCategory.OTHER_CEREMONY,
  UnitCategory.COCKTAIL_TABLE,
  UnitCategory.BAR_DECOR,
  UnitCategory.OTHER_COCKTAIL,
  UnitCategory.RECEPTION_TABLE,
  UnitCategory.SWEETHEART_TABLE,
  UnitCategory.OTHER_RECEPTION
]
export const UNIT_PRODUCT_TYPE_V2 = [
  ProposalUnitProductType.BRIDAL_BOUQUET,
  ProposalUnitProductType.GROOM_BOUTS,
  ProposalUnitProductType.BRIDAL_HAIR_PIECE,
  ProposalUnitProductType.BRIDESMAIDS_BOUQUET,
  ProposalUnitProductType.BOUTS,
  ProposalUnitProductType.CORSAGE,
  ProposalUnitProductType.FLOWER_GIRL_CROWN,
  ProposalUnitProductType.BASKET_PETALS,
  ProposalUnitProductType.LOOSE_PETALS,
  ProposalUnitProductType.ARCH_CLUSTER,
  ProposalUnitProductType.CLOUD,
  ProposalUnitProductType.POSIES,
  ProposalUnitProductType.WELCOME_SIGN_DECOR,
  ProposalUnitProductType.CHAIR_FLOWER,
  ProposalUnitProductType.MANTLE_DECOR,
  ProposalUnitProductType.BUD_VASE,
  ProposalUnitProductType.ROUND_GREENERY,
  ProposalUnitProductType.FARM_GREENERY,
  ProposalUnitProductType.CENTERPIECE,
  ProposalUnitProductType.GARLAND,
  ProposalUnitProductType.CAKE_FLOWER
]

export const SideBarV1 = [
  { key: TemplateProposalUnitType.BRIDAL_BOUQUET, value: 'Bridal Bouquet' },
  { key: TemplateProposalUnitType.GROOM_BOUTS, value: 'Groom Boutonnière' },
  { key: TemplateProposalUnitType.BRIDESMAIDS_BOUQUET, value: 'Bridesmaid Bouquet' },
  { key: TemplateProposalUnitType.BOUTS, value: 'Boutonnière' },
  { key: TemplateProposalUnitType.CORSAGE, value: 'Corsage' },
  { key: TemplateProposalUnitType.BRIDAL_HAIR_PIECE, value: 'Bridal Hair Piece' },
  { key: TemplateProposalUnitType.FLOWER_GIRL_CROWN, value: 'Flower Child' },
  { key: TemplateProposalUnitType.WELCOME_SIGN_DECOR, value: 'Welcome Sign Decor' },
  { key: TemplateProposalUnitType.CEREMONY_DECOR, value: 'Ceremony Decor' },
  { key: TemplateProposalUnitType.AISLE_DECOR, value: 'Aisle Decor' },
  { key: TemplateProposalUnitType.COCKTAIL_DECOR, value: 'Cocktail Decor' },
  { key: TemplateProposalUnitType.MANTLE_DECOR, value: 'Mantel Decor' },
  { key: TemplateProposalUnitType.ROUND_TABLE_DECOR, value: 'Round Table Decor' },
  { key: TemplateProposalUnitType.FARM_TABLE_DECOR, value: 'Long Table Decor' },
  { key: TemplateProposalUnitType.CAKE_FLOWER, value: 'Cake Flower Decor' },
  { key: TemplateProposalUnitType.SWEETHEART_TABLE_DECOR, value: 'Sweetheart Table Decor' },
  { key: TemplateProposalUnitType.SERVICE_LEVELS, value: 'Service Levels' },
  { key: TemplateProposalUnitType.EXTRA_FEES, value: 'Extra fees' }
]
export const SideBarV2 = [
  { key: EventCategory.PERSONALS, value: 'Personals', noHref: true },
  { key: `${UnitCategory.COUPLES_FLOWERS}-${TemplateProposalUnitType.BRIDAL_BOUQUET}`, value: 'Bridal Bouquet' },
  { key: `${UnitCategory.COUPLES_FLOWERS}-${TemplateProposalUnitType.GROOM_BOUTS}`, value: 'Groom Boutonnière' },
  { key: `${UnitCategory.COUPLES_FLOWERS}-${TemplateProposalUnitType.BRIDAL_HAIR_PIECE}`, value: 'Bridal Hair Piece' },

  { key: `${UnitCategory.WEDDING_PARTY}-${TemplateProposalUnitType.BRIDESMAIDS_BOUQUET}`, value: 'Bridesmaid Bouquet' },
  { key: `${UnitCategory.WEDDING_PARTY}-${TemplateProposalUnitType.BOUTS}`, value: 'Boutonnière' },

  { key: `${UnitCategory.OTHER_PERSONALS}-${TemplateProposalUnitType.CORSAGE}`, value: 'Corsage' },
  { key: `${UnitCategory.OTHER_PERSONALS}-${TemplateProposalUnitType.FLOWER_GIRL_CROWN}`, value: 'Flower Child' },
  { key: `${UnitCategory.OTHER_PERSONALS}-${TemplateProposalUnitType.BASKET_PETALS}`, value: 'Basket Petals' },

  { key: EventCategory.CEREMONY, value: 'Ceremony Decor', noHref: true },
  { key: `${UnitCategory.ALTAR_DECOR}-${TemplateProposalUnitType.ARCH_CLUSTER}`, value: 'Arch Cluster' },
  { key: `${UnitCategory.ALTAR_DECOR}-${TemplateProposalUnitType.CLOUD}`, value: 'Cloud' },

  { key: `${UnitCategory.AISLE_DECOR}-${TemplateProposalUnitType.POSIES}`, value: 'Posies' },
  { key: `${UnitCategory.AISLE_DECOR}-${TemplateProposalUnitType.LOOSE_PETALS}`, value: 'Loose Petals' },
  { key: `${UnitCategory.AISLE_DECOR}-${TemplateProposalUnitType.CLOUD}`, value: 'Cloud' },

  { key: `${UnitCategory.OTHER_CEREMONY}-${TemplateProposalUnitType.WELCOME_SIGN_DECOR}`, value: 'Welcome Sign Decor' },
  { key: `${UnitCategory.OTHER_CEREMONY}-${TemplateProposalUnitType.MANTLE_DECOR}`, value: 'Mantel Decor' },
  { key: `${UnitCategory.OTHER_CEREMONY}-${TemplateProposalUnitType.CHAIR_FLOWER}`, value: 'Chair Flower' },

  { key: EventCategory.COCKTAIL, value: 'Cocktail Decor', noHref: true },
  { key: `${UnitCategory.COCKTAIL_TABLE}-${TemplateProposalUnitType.BUD_VASE}`, value: 'Bud vase' },
  { key: `${UnitCategory.COCKTAIL_TABLE}-${TemplateProposalUnitType.ROUND_GREENERY}`, value: 'Round Greenery' },

  { key: `${UnitCategory.BAR_DECOR}-${TemplateProposalUnitType.CENTERPIECE}`, value: 'Centerpiece' },
  { key: `${UnitCategory.BAR_DECOR}-${TemplateProposalUnitType.CLOUD}`, value: 'Cloud' },
  { key: `${UnitCategory.BAR_DECOR}-${TemplateProposalUnitType.GARLAND}`, value: 'Garland' },
  { key: `${UnitCategory.BAR_DECOR}-${TemplateProposalUnitType.BUD_VASE}`, value: 'Bud vase' },

  { key: `${UnitCategory.OTHER_COCKTAIL}-${TemplateProposalUnitType.FARM_GREENERY}`, value: 'Long Table Greenery' },
  { key: `${UnitCategory.OTHER_COCKTAIL}-${TemplateProposalUnitType.CENTERPIECE}`, value: 'Centerpiece' },

  { key: EventCategory.RECEPTION, value: 'Reception Decor', noHref: true },
  { key: `${UnitCategory.RECEPTION_TABLE}-${TemplateProposalUnitType.CENTERPIECE}`, value: 'Centerpiece' },
  { key: `${UnitCategory.RECEPTION_TABLE}-${TemplateProposalUnitType.BUD_VASE}`, value: 'Bud vase' },
  { key: `${UnitCategory.RECEPTION_TABLE}-${TemplateProposalUnitType.FARM_GREENERY}`, value: 'Long Table Greenery' },
  { key: `${UnitCategory.RECEPTION_TABLE}-${TemplateProposalUnitType.ROUND_GREENERY}`, value: 'Round Greenery' },

  { key: `${UnitCategory.SWEETHEART_TABLE}-${TemplateProposalUnitType.CENTERPIECE}`, value: 'Centerpiece' },
  { key: `${UnitCategory.SWEETHEART_TABLE}-${TemplateProposalUnitType.FARM_GREENERY}`, value: 'Long Table Greenery' },
  { key: `${UnitCategory.SWEETHEART_TABLE}-${TemplateProposalUnitType.CLOUD}`, value: 'Cloud' },
  { key: `${UnitCategory.SWEETHEART_TABLE}-${TemplateProposalUnitType.GARLAND}`, value: 'Garland' },

  { key: `${UnitCategory.OTHER_RECEPTION}-${TemplateProposalUnitType.CAKE_FLOWER}`, value: 'Cake Flower Decor' },
  { key: `${UnitCategory.OTHER_RECEPTION}-${TemplateProposalUnitType.CHAIR_FLOWER}`, value: 'Chair Flower' },

  { key: TemplateProposalUnitType.SERVICE_LEVELS, value: 'Service Levels' },
  { key: TemplateProposalUnitType.EXTRA_FEES, value: 'Extra fees' }
]

export const mappingUnitName = (unitType: string) => {
  const MAPPING_NAME: { [k: string]: string } = {
    [TemplateProposalUnitType.BRIDAL_BOUQUET]: 'Bridal Bouquet',
    [TemplateProposalUnitType.GROOM_BOUTS]: 'Groom Boutonnière',
    [TemplateProposalUnitType.BRIDESMAIDS_BOUQUET]: 'Bridesmaid Bouquet',
    [TemplateProposalUnitType.BOUTS]: 'Boutonnière',
    [TemplateProposalUnitType.CORSAGE]: 'Corsage',
    [TemplateProposalUnitType.BRIDAL_HAIR_PIECE]: 'Bridal Hair Piece',
    [TemplateProposalUnitType.FLOWER_GIRL_CROWN]: 'Flower Child',
    [TemplateProposalUnitType.WELCOME_SIGN_DECOR]: 'Welcome Sign Decor',
    [TemplateProposalUnitType.CEREMONY_DECOR]: 'Ceremony Decor',
    [TemplateProposalUnitType.AISLE_DECOR]: 'Aisle Decor',
    [TemplateProposalUnitType.COCKTAIL_DECOR]: 'Cocktail Decor',
    [TemplateProposalUnitType.MANTLE_DECOR]: 'Mantel Decor',
    [TemplateProposalUnitType.ROUND_TABLE_DECOR]: 'Round Table Decor',
    [TemplateProposalUnitType.FARM_TABLE_DECOR]: 'Long Table Decor',
    [TemplateProposalUnitType.CAKE_FLOWER]: 'Cake Flower Decor',
    [TemplateProposalUnitType.SWEETHEART_TABLE_DECOR]: 'Sweetheart Table Decor',
    [TemplateProposalUnitType.SERVICE_LEVELS]: 'Service Levels',
    [TemplateProposalUnitType.EXTRA_FEES]: 'Extra fees'
  }
  return MAPPING_NAME[unitType]
}

export const mappingCategoryName = (category: string) => {
  const MAPPING_NAME: { [k: string]: string } = {
    [UnitCategory.COUPLES_FLOWERS]: 'Couples Flowers',
    [UnitCategory.WEDDING_PARTY]: 'Wedding Party',
    [UnitCategory.OTHER_PERSONALS]: 'Other Personals',
    [UnitCategory.AISLE_DECOR]: 'Aisle Decor',
    [UnitCategory.ALTAR_DECOR]: 'Altar Decor',
    [UnitCategory.OTHER_CEREMONY]: 'Other Ceremony Decor',
    [UnitCategory.COCKTAIL_TABLE]: 'Cocktail Table Decor',
    [UnitCategory.BAR_DECOR]: 'Bar Decor',
    [UnitCategory.OTHER_COCKTAIL]: 'Other Cocktail Decor',
    [UnitCategory.RECEPTION_TABLE]: 'Reception Table Decor',
    [UnitCategory.SWEETHEART_TABLE]: 'Sweetheart Table Decor',
    [UnitCategory.OTHER_RECEPTION]: 'Other Reception Decor'
  }
  return MAPPING_NAME[category]
}

export const mappingEventCategoryName = (eventCategory: string) => {
  const MAPPING_NAME: { [k: string]: string } = {
    [EventCategory.PERSONALS]: 'Personals',
    [EventCategory.CEREMONY]: 'Ceremony Decor',
    [EventCategory.COCKTAIL]: 'Cocktail Decor',
    [EventCategory.RECEPTION]: 'Reception Decor'
  }
  return MAPPING_NAME[eventCategory]
}

export const mappingProductType = (productType: string) => {
  const MAPPING_NAME: { [k: string]: string } = {
    [ProposalUnitProductType.BRIDAL_BOUQUET]: 'Bridal Bouquet',
    [ProposalUnitProductType.GROOM_BOUTS]: 'Groom’s Bout',
    [ProposalUnitProductType.BRIDAL_HAIR_PIECE]: 'Bridal Hair Piece',
    [ProposalUnitProductType.BRIDESMAIDS_BOUQUET]: 'Bridesmaid Bouquet',
    [ProposalUnitProductType.BOUTS]: 'Boutonniere',
    [ProposalUnitProductType.CORSAGE]: 'Corsage',
    [ProposalUnitProductType.FLOWER_GIRL_CROWN]: 'Flower Child',
    [ProposalUnitProductType.BASKET_PETALS]: 'Basket Petals',
    [ProposalUnitProductType.LOOSE_PETALS]: 'Loose Petals',
    [ProposalUnitProductType.ARCH_CLUSTER]: 'Arch Cluster',
    [ProposalUnitProductType.CLOUD]: 'Cloud',
    [ProposalUnitProductType.POSIES]: 'Posies',
    [ProposalUnitProductType.WELCOME_SIGN_DECOR]: 'Welcome Sign Decor',
    [ProposalUnitProductType.CHAIR_FLOWER]: 'Chair Flower',
    [ProposalUnitProductType.MANTLE_DECOR]: 'Mantle Decor',
    [ProposalUnitProductType.BUD_VASE]: 'Bud vase',
    [ProposalUnitProductType.ROUND_GREENERY]: 'Round Greenery',
    [ProposalUnitProductType.FARM_GREENERY]: 'Long Table Greenery',
    [ProposalUnitProductType.CENTERPIECE]: 'Centerpiece',
    [ProposalUnitProductType.GARLAND]: 'Garland',
    [ProposalUnitProductType.CAKE_FLOWER]: 'Cake Flower Decor',
    [ProposalUnitProductType.CUSTOM]: 'Custom'
  }

  return MAPPING_NAME[productType]
}

export const DEFAULT_UNIT_TEMPLATE_ORDER: { [a: string]: { [b: string]: string[] } } = {
  [EventCategory.PERSONALS]: {
    [UnitCategory.COUPLES_FLOWERS]: [
      TemplateProposalUnitType.BRIDAL_BOUQUET,
      TemplateProposalUnitType.GROOM_BOUTS,
      TemplateProposalUnitType.BRIDAL_HAIR_PIECE,
      TemplateProposalUnitType.CUSTOM
    ],
    [UnitCategory.WEDDING_PARTY]: [
      TemplateProposalUnitType.BRIDESMAIDS_BOUQUET,
      TemplateProposalUnitType.BOUTS,
      TemplateProposalUnitType.CUSTOM
    ],
    [UnitCategory.OTHER_PERSONALS]: [
      TemplateProposalUnitType.CORSAGE,
      TemplateProposalUnitType.FLOWER_GIRL_CROWN,
      TemplateProposalUnitType.BASKET_PETALS,
      TemplateProposalUnitType.CUSTOM
    ]
  },
  [EventCategory.CEREMONY]: {
    [UnitCategory.ALTAR_DECOR]: [
      TemplateProposalUnitType.ARCH_CLUSTER,
      TemplateProposalUnitType.CLOUD,
      TemplateProposalUnitType.CUSTOM
    ],
    [UnitCategory.AISLE_DECOR]: [
      TemplateProposalUnitType.POSIES,
      TemplateProposalUnitType.LOOSE_PETALS,
      TemplateProposalUnitType.CLOUD,
      TemplateProposalUnitType.CUSTOM
    ],
    [UnitCategory.OTHER_CEREMONY]: [
      TemplateProposalUnitType.WELCOME_SIGN_DECOR,
      TemplateProposalUnitType.MANTLE_DECOR,
      TemplateProposalUnitType.CHAIR_FLOWER,
      TemplateProposalUnitType.CUSTOM
    ]
  },
  [EventCategory.COCKTAIL]: {
    [UnitCategory.COCKTAIL_TABLE]: [
      TemplateProposalUnitType.BUD_VASE,
      TemplateProposalUnitType.ROUND_GREENERY,
      TemplateProposalUnitType.CUSTOM
    ],
    [UnitCategory.BAR_DECOR]: [
      TemplateProposalUnitType.CENTERPIECE,
      TemplateProposalUnitType.CLOUD,
      TemplateProposalUnitType.GARLAND,
      TemplateProposalUnitType.BUD_VASE,
      TemplateProposalUnitType.CUSTOM
    ],
    [UnitCategory.OTHER_COCKTAIL]: [
      TemplateProposalUnitType.FARM_GREENERY,
      TemplateProposalUnitType.CENTERPIECE,
      TemplateProposalUnitType.CUSTOM
    ]
  },
  [EventCategory.RECEPTION]: {
    [UnitCategory.RECEPTION_TABLE]: [
      TemplateProposalUnitType.CENTERPIECE,
      TemplateProposalUnitType.BUD_VASE,
      TemplateProposalUnitType.FARM_GREENERY,
      TemplateProposalUnitType.ROUND_GREENERY,
      TemplateProposalUnitType.CUSTOM
    ],
    [UnitCategory.SWEETHEART_TABLE]: [
      TemplateProposalUnitType.CENTERPIECE,
      TemplateProposalUnitType.FARM_GREENERY,
      TemplateProposalUnitType.CLOUD,
      TemplateProposalUnitType.GARLAND,
      TemplateProposalUnitType.CUSTOM
    ],
    [UnitCategory.OTHER_RECEPTION]: [
      TemplateProposalUnitType.CAKE_FLOWER,
      TemplateProposalUnitType.CHAIR_FLOWER,
      TemplateProposalUnitType.CUSTOM
    ]
  }
}

export const DEFAULT_UNIT_ORDER = Object.keys(DEFAULT_UNIT_TEMPLATE_ORDER).flatMap((group) =>
  Object.keys(DEFAULT_UNIT_TEMPLATE_ORDER[group]).flatMap((category) =>
    DEFAULT_UNIT_TEMPLATE_ORDER[group][category].map((unit) => `${category}-${unit}`)
  )
)

export const templateProductToProductPayload = (
  templateProduct: TemplateProduct,
  additionalProps?: { isSequenceIndexChanged?: boolean; isDeleted?: boolean }
): ProductPayload => {
  return {
    id: templateProduct.id,
    productId: templateProduct.productId || templateProduct.product.id,
    productType: templateProduct.productType,
    proposalUnitCategory: templateProduct.proposalUnitCategory,
    templateId: templateProduct.templateId,
    unitProductType: templateProduct.unitProductType,
    unitType: templateProduct.unitType,
    sequenceIndex: templateProduct.sequenceIndex,
    isSelected: templateProduct.isSelected,
    ...additionalProps
  }
}
