import {
  IGetNotiDetailPayload,
  IGetNotificationListPayload,
  IGetOverviewProgressPayload,
  IMarkCompletePayload
} from 'src/common/notificationTask.interface'

import {
  // postHttp,
  getHttp,
  // putHttp,
  patchHttp
} from './apiLayer'

export const getNotificationList = async (payload: IGetNotificationListPayload) => {
  const { sortBy, from, to, search, page, fmids, type, status } = payload
  let url = `notification-tasks/list?`
  if (search) {
    url += `search=${encodeURIComponent(search)}&`
  }
  if (from && to) {
    url += `from=${from}&to=${to}&`
  }
  if (page) {
    url += `page=${page}&`
  }
  if (sortBy) {
    url += `sortBy=${sortBy}&`
  }
  if (fmids) {
    url += `fmids=${encodeURIComponent(fmids)}&`
  }
  if (type) {
    url += `type=${encodeURIComponent(type)}&`
  }
  if (status) {
    url += `status=${encodeURIComponent(status)}`
  }

  const data = await getHttp(url)
  return data.data
}

export const countTheNoti = async (payload: IGetNotificationListPayload) => {
  const { sortBy, from, to, search, fmids, type, status } = payload
  let url = `notification-tasks/get-count?`
  if (search) {
    url += `search=${encodeURIComponent(search)}&`
  }
  if (from && to) {
    url += `from=${from}&to=${to}&`
  }
  if (sortBy) {
    url += `sortBy=${sortBy}&`
  }
  if (fmids) {
    url += `fmids=${encodeURIComponent(fmids)}&`
  }
  if (type) {
    url += `type=${encodeURIComponent(type)}&`
  }
  if (status) {
    url += `status=${encodeURIComponent(status)}`
  }

  const data = await getHttp(url)
  return data.data
}

export const getFloralManagerForNP = async () => {
  const url = `notification-tasks/floral-managers`
  const data = await getHttp(url)
  return data.data
}

export const getOverviewProgress = async (payload: IGetOverviewProgressPayload) => {
  const { ids, period } = payload
  let url = `notification-tasks/get-progress?`

  if (ids) {
    url += `ids=${encodeURIComponent(ids)}&`
  }
  if (period) {
    url += `period=${period}&`
  }
  const data = await getHttp(url)
  return data.data
}

export const getNotiDetail = async (payload: IGetNotiDetailPayload) => {
  const { eventId } = payload
  const url = `notification-tasks/detail/${eventId}`
  const data = await getHttp(url)
  return data.data
}

export const markTaskComplete = async (payload: IMarkCompletePayload) => {
  const { notiId } = payload
  const url = `notification-tasks/mark-complete/${notiId}`
  const data = await patchHttp(url, {})
  return data.data
}
