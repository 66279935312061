import React from 'react'
import { TextFieldProps } from '@material-ui/core'
import { REGEX } from 'src/common/regex'
import { FormInput2 } from './FormInput'

export const QuantityInput = (props: TextFieldProps) => {
  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.trim() !== '') {
      const formatValue = parseInt(event.target.value)
      event.target.value = Number.isNaN(formatValue) ? '' : `${formatValue}`
    }

    // eslint-disable-next-line eqeqeq
    if (props.value != event.target.value) {
      props.onChange && props.onChange(event)
    }
  }

  // return <TextField inputMode="tel" {...props} onChange={handleChangeValue} />
  return <FormInput2 {...props} inputMode="tel" onChange={handleChangeValue} helperText="" />
}

export const QuantityInputDecimal = (props: TextFieldProps) => {
  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (REGEX.period.test(event.target.value) || event.target.value === '') {
      // eslint-disable-next-line eqeqeq
      if (props.value != event.target.value) {
        props.onChange && props.onChange(event)
      }
    } else {
      const formatValue = parseFloat(event.target.value)
      event.target.value = Number.isNaN(formatValue) ? '' : `${formatValue}`
    }
  }

  // return <TextField {...props} inputMode="decimal" onChange={handleChangeValue} />
  return <FormInput2 {...props} inputMode="decimal" onChange={handleChangeValue} helperText="" />
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const QuantityInputWithRef = React.forwardRef((props: TextFieldProps, ref: any) => {
  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.trim() !== '') {
      const formatValue = parseInt(event.target.value)
      event.target.value = Number.isNaN(formatValue) ? '' : `${formatValue}`
    }

    // eslint-disable-next-line eqeqeq
    if (props.value != event.target.value) {
      props.onChange && props.onChange(event)
    }
  }

  // return <TextField ref={ref} inputMode="tel" {...props} onChange={handleChangeValue} />
  return <FormInput2 ref={ref} inputMode="tel" {...props} onChange={handleChangeValue} helperText="" />
})

QuantityInputWithRef.displayName = 'QuantityInputWithRef'
