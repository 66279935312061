import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'
import { EventOrderResponse } from 'src/api/postOrders'
import { QuantityMode } from 'src/api/recipes'

import {
  Stem,
  Product,
  EventInfo,
  ReducerProduct,
  StemOrderUnit,
  RecipeHardGood,
  EventModel,
  EventOrder,
  OrderSupplier
} from 'src/common/common.interface'
import { GENERIC_SUPPLIER } from 'src/common/constants'
import { PhotosNeeded } from 'src/common/enum'
import { onlyUnique } from 'src/common/util'
import { RecipeFormula } from 'src/api/recipeFormula'
import {
  addOrUpdateProduct,
  filterStems,
  convertProductsEventOrderToReducerProducts,
  getSummary,
  removeProduct,
  updateProductQuantity,
  updateProductStyleNotes,
  convertColorStyleToColorFilter,
  ISummaryStem,
  updateProductApproved,
  updateProductFieldsForDesignGuide,
  updateProductQuantityMode,
  updateProductOverrideImage,
  updateProductNickName,
  changeStemOfRecipe,
  filteredSubmittedOrderSuppliers
} from './helpers'

export enum ACTION_STEM_TYPE {
  createOrUpdate = 'createOrUpdate',
  deleteOrUpdate = 'deleteOrUpdate'
}

export interface IActionStem {
  action: ACTION_STEM_TYPE
  stem: StemOrderUnit
}

export interface RecipeBuilderState {
  allSuppliers: string[]
  currentProduct: Product | null
  currentColors: string[]
  currentSuppliers: string[]
  currentStemList: Stem[]
  recipeTemplate?: IRecipeTemplate
  productData: Product[]
  eventInfo?: EventInfo
  products: ReducerProduct[]
  eventOrderId?: number
  eventOrder: EventOrder
  submittedOrderSuppliers: OrderSupplier[]
  stemsOrder: ISummaryStem
  skeletonRecipeStem: (string | number)[]
  savingDataCount: number
  loadingProductData: boolean
  loadingStemData: boolean
  loadingSupplierStem: boolean
  styleInformation?: EventModel
  recipeFormulas?: RecipeFormula[]
}

const initialState: RecipeBuilderState = {
  productData: [],
  currentColors: [],
  savingDataCount: 0,
  currentProduct: null,
  allSuppliers: [GENERIC_SUPPLIER],
  currentSuppliers: [],
  currentStemList: [],
  recipeTemplate: undefined,
  eventInfo: undefined,
  products: [],
  stemsOrder: [],
  eventOrderId: undefined,
  eventOrder: {} as EventOrder,
  submittedOrderSuppliers: [],
  skeletonRecipeStem: [],
  loadingProductData: true,
  loadingStemData: true,
  loadingSupplierStem: true,
  styleInformation: undefined,
  recipeFormulas: undefined
}

export interface UpdateStemPayload {
  recipeId: number
  stem: StemOrderUnit
}

export interface UpdateProductQuantityPayload {
  productId: number
  quantity: number
}

export interface UpdateRecipeProductPayload {
  recipeId: number
  quantity?: number
  styleNotes?: string
  approved?: boolean
  fieldType?: string
  fieldValue?: string
  fieldValueArray?: PhotosNeeded[]
  quantityMode?: QuantityMode
  imageOverride?: string
  nickName?: string
  changeStem?: boolean
  oldStemId?: number
  newStem?: Stem
  forApplyProductType?: boolean
  prepTimeMinutes?: number | null
  designTimeMinutes?: number | null
  installTimeMinutes?: number | null
  loadingTimeMinutes?: number | null
  photosNeeded?: PhotosNeeded[]
}

export interface IRecipeTemplate {
  color: string[]
}
export interface RemoveStemPayload {
  recipeId: number
  stem: StemOrderUnit
}

export interface IStemsOrders {
  [id: number]: StemOrderUnit
}

export interface UpdateHardGoodPayload {
  recipeId: number
  recipeHardGood: RecipeHardGood
}

export interface RemoveHardGoodPayload {
  recipeId: number
  recipeHardGood: RecipeHardGood
}

export interface RemoveProposalInspirationPhotoPayload {
  proposalIndex: number
  proposalInspirationPhotoIndex: number
}

export interface UpdateProposalInspirationPhotoPayload {
  proposalIndex: number
  proposalInspirationPhotoIndex: number
  isHidden?: boolean
}

const recipeSlice = createSlice({
  name: 'recipe',
  initialState,
  reducers: {
    addProductsData(state, action: PayloadAction<Product[]>) {
      state.productData = action.payload
    },

    addAProductData(state, action: PayloadAction<Product>) {
      state.productData.push(action.payload)
    },

    updateCurrentStemList(state, action: PayloadAction<Stem[]>) {
      state.currentStemList = filterStems(action.payload, state.currentColors, state.currentSuppliers)
    },

    addEventData(state, action: PayloadAction<EventInfo>) {
      if (action.payload?.stylePreference?.colors) {
        const colors = convertColorStyleToColorFilter(action.payload?.stylePreference?.colors)
        state.currentColors = onlyUnique([...colors, 'Green'])
      }

      state.eventInfo = action.payload
    },

    addEventOrderId(state, action: PayloadAction<EventOrderResponse>) {
      const products = convertProductsEventOrderToReducerProducts(action.payload?.products ?? [])

      state.products = products
      state.eventOrderId = action.payload.eventOrderId
      state.eventOrder = action.payload.eventOrder
      state.submittedOrderSuppliers = filteredSubmittedOrderSuppliers(action.payload.eventOrder)
      state.stemsOrder = getSummary(products)
    },

    setReducerProductList(state, action: PayloadAction<ReducerProduct[]>) {
      state.products = action.payload
    },

    addProduct(state, action: PayloadAction<ReducerProduct>) {
      state.products = addOrUpdateProduct(state.products, action.payload)
      state.stemsOrder = getSummary(state.products)
    },

    setCurrentProduct(state, action: PayloadAction<Product | null>) {
      state.currentProduct = action.payload
    },

    updateRecipeProduct(state, action: PayloadAction<UpdateRecipeProductPayload>) {
      const {
        recipeId,
        quantity,
        styleNotes,
        approved,
        fieldType,
        fieldValue,
        fieldValueArray,
        quantityMode,
        imageOverride,
        nickName,
        changeStem,
        oldStemId,
        newStem,
        forApplyProductType
      } = action.payload

      if (forApplyProductType) {
        const reducerProduct = state.products.find((product) => product.recipeId === recipeId)
        if (reducerProduct) {
          reducerProduct.prepTimeMinutes = action.payload.prepTimeMinutes
          reducerProduct.designTimeMinutes = action.payload.designTimeMinutes
          reducerProduct.installTimeMinutes = action.payload.installTimeMinutes
          reducerProduct.loadingTimeMinutes = action.payload.loadingTimeMinutes
          reducerProduct.photosNeeded = action.payload.photosNeeded
          reducerProduct.styleNotes = action.payload.styleNotes || ''
        }
      }
      if (quantity) {
        const products = updateProductQuantity(state.products, quantity, recipeId)

        state.products = products
        state.stemsOrder = getSummary(products)
      }

      if (styleNotes || styleNotes === '') {
        const products = updateProductStyleNotes(state.products, styleNotes, recipeId)
        state.products = products
      }

      if (quantityMode) {
        const products = updateProductQuantityMode(state.products, quantityMode, recipeId)
        state.products = products
        state.stemsOrder = getSummary(products)
      }

      if (imageOverride) {
        const products = updateProductOverrideImage(state.products, imageOverride, recipeId)
        state.products = products
      }

      if (fieldType) {
        const products = updateProductFieldsForDesignGuide(
          state.products,
          recipeId,
          fieldType,
          fieldValue,
          fieldValueArray
        )
        state.products = products
      }

      if (typeof approved !== 'undefined') {
        const products = updateProductApproved(state.products, approved, recipeId)
        state.products = products
      }

      if (nickName) {
        const products = updateProductNickName(state.products, nickName, recipeId)
        state.products = products
      }

      if (changeStem) {
        const products = changeStemOfRecipe(state.products, oldStemId || -1, newStem || ({} as Stem), recipeId)
        state.products = products
        state.stemsOrder = getSummary(products)
      }
    },

    updateRecipeStemByProduct(state, action: PayloadAction<ReducerProduct>) {
      const { stems, recipeId } = action.payload
      const productFind = state.products.find((product) => product.recipeId === recipeId)

      if (productFind) {
        productFind.stems = stems
      }
    },

    removeProduct(state, action: PayloadAction<ReducerProduct>) {
      const products = removeProduct(state.products, action.payload)
      state.products = products
      state.stemsOrder = getSummary(products)
    },

    updateSequenceProduct(state, action: PayloadAction<ReducerProduct[]>) {
      state.products = action.payload
    },

    addStem(state, action: PayloadAction<UpdateStemPayload>) {
      const update = action.payload
      const { products } = state

      const product = products.find((x) => x.recipeId === update.recipeId)
      product?.stems.push(update.stem)
      const newSummary = getSummary(products)

      state.products = products
      state.stemsOrder = newSummary
    },

    updateStemCount(state, action: PayloadAction<UpdateStemPayload>) {
      const update = action.payload
      const { products } = state
      const index = products.findIndex((prod) => prod.recipeId === update.recipeId)
      const stemIndex = products[index].stems.findIndex((s) => s.recipeStemId === update.stem.recipeStemId)
      products[index].stems[stemIndex] = update.stem
      const newSummary = getSummary(products)

      state.products = products
      state.stemsOrder = newSummary
    },

    removeStem(state, action: PayloadAction<RemoveStemPayload>) {
      const { stem, recipeId } = action.payload
      const { products } = state

      const productsCopy = cloneDeep(products)
      const product = productsCopy.find((x) => x.recipeId === recipeId)

      if (!product) return state

      product.stems = product?.stems.filter((item) => item.recipeStemId !== stem.recipeStemId)

      state.products = productsCopy
      state.stemsOrder = getSummary(productsCopy)
    },

    updateColor(state, action: PayloadAction<string[]>) {
      state.currentColors = action.payload
    },

    updateSupplier(state, action: PayloadAction<string[]>) {
      state.currentSuppliers = action.payload
    },

    startSkeletonRecipeStem(state, action: PayloadAction<string | number>) {
      state.skeletonRecipeStem = [...state.skeletonRecipeStem, action.payload]
    },

    endSkeletonRecipeStem(state, action: PayloadAction<string | number>) {
      state.skeletonRecipeStem = state.skeletonRecipeStem.filter((skeleton) => skeleton !== action.payload)
    },

    setLoadingStemData(state, action: PayloadAction<boolean>) {
      state.loadingStemData = action.payload
    },

    setLoadingProductData(state, action: PayloadAction<boolean>) {
      state.loadingProductData = action.payload
    },

    setLoadingSupplierStem(state, action: PayloadAction<boolean>) {
      state.loadingSupplierStem = action.payload
    },

    setSavingDataCount(state, action: PayloadAction<number>) {
      state.savingDataCount += action.payload
    },

    addHardGood(state, action: PayloadAction<UpdateHardGoodPayload[]>) {
      const updates = action.payload
      const { products } = state

      updates.forEach((update) => {
        const product = products.find((x) => x.recipeId === update.recipeId)
        product?.recipeHardGoods.push(update.recipeHardGood)
      })

      state.products = products
    },

    update1RecipeHardGoodData(state, action: PayloadAction<UpdateHardGoodPayload>) {
      const update = action.payload
      const { products } = state
      const index = products.findIndex((prod) => prod.recipeId === update.recipeId)
      if (index > -1) {
        const recipeHardGoodIndex = products[index].recipeHardGoods.findIndex(
          (rhg) => rhg.id === update.recipeHardGood.id
        )
        if (recipeHardGoodIndex > -1) {
          products[index].recipeHardGoods[recipeHardGoodIndex] = update.recipeHardGood

          state.products = products
        }
      }
    },

    removeHardGood(state, action: PayloadAction<RemoveHardGoodPayload>) {
      const { recipeHardGood, recipeId } = action.payload
      const { products } = state

      const productsCopy = cloneDeep(products)
      const product = productsCopy.find((x) => x.recipeId === recipeId)

      if (!product) return state

      product.recipeHardGoods = product?.recipeHardGoods.filter((item) => item.id !== recipeHardGood.id)

      state.products = productsCopy
    },

    addStyleInformationData(state, action: PayloadAction<EventModel>) {
      state.styleInformation = action.payload?.customer ? action.payload : undefined
    },

    removeProposalInspirationPhoto(state, action: PayloadAction<RemoveProposalInspirationPhotoPayload>) {
      const styleInformation = { ...state.styleInformation }
      if (styleInformation) {
        if (styleInformation?.proposals && styleInformation?.proposals[action.payload.proposalIndex]) {
          styleInformation?.proposals[action.payload.proposalIndex].proposalInspirationPhotos.splice(
            action.payload.proposalInspirationPhotoIndex,
            1
          )
        }
        state.styleInformation = {
          ...styleInformation,
          proposals: styleInformation.proposals || []
        } as EventModel
      }
    },
    updateOneProposalInspirationPhoto(state, action: PayloadAction<UpdateProposalInspirationPhotoPayload>) {
      const styleInformation = { ...state.styleInformation }
      if (styleInformation) {
        if (
          styleInformation?.proposals &&
          styleInformation?.proposals[action.payload.proposalIndex] &&
          styleInformation?.proposals[action.payload.proposalIndex].proposalInspirationPhotos
        ) {
          if (
            typeof action.payload.isHidden !== 'undefined' &&
            styleInformation?.proposals[action.payload.proposalIndex].proposalInspirationPhotos[
              action.payload.proposalInspirationPhotoIndex
            ]
          ) {
            styleInformation.proposals[action.payload.proposalIndex].proposalInspirationPhotos[
              action.payload.proposalInspirationPhotoIndex
            ] = {
              ...styleInformation?.proposals[action.payload.proposalIndex].proposalInspirationPhotos[
                action.payload.proposalInspirationPhotoIndex
              ],
              isHidden: action.payload.isHidden
            }
          }
        }
        state.styleInformation = {
          ...styleInformation,
          proposals: styleInformation.proposals || []
        } as EventModel
      }
    },
    updateOneSubmittedOrderSupplier(state, action: PayloadAction<OrderSupplier>) {
      const index = state.eventOrder?.orderSuppliers?.findIndex((sos) => sos.id === action.payload.id)
      if (index !== undefined && index >= 0) {
        if (state.eventOrder?.orderSuppliers) {
          state.eventOrder.orderSuppliers[index] = action.payload
        }
        state.submittedOrderSuppliers = filteredSubmittedOrderSuppliers(state.eventOrder)
      }
    },
    updateReducerProductListAndStemOrder(state, action: PayloadAction<ReducerProduct[]>) {
      state.products = action.payload
      state.stemsOrder = getSummary(state.products)
    },
    setRecipeFormulas(state, action: PayloadAction<RecipeFormula[]>) {
      state.recipeFormulas = action.payload
    },
    addOneRecipeFormulas(state, action: PayloadAction<RecipeFormula>) {
      state.recipeFormulas = state.recipeFormulas
        ?.concat([action.payload])
        .sort((a, b) => a.productTypeId - b.productTypeId)
    }
  }
})

// type ex: recipe/addProductsData
export const recipeAction = recipeSlice.actions

export default recipeSlice.reducer
