import React from 'react'

import HandleBrowserBackButton from './HandleBrowserBackButton'

export const Trigger = () => {
  return (
    <>
      <HandleBrowserBackButton />
    </>
  )
}
