/* eslint-disable @typescript-eslint/no-use-before-define */
import { createAction } from '@reduxjs/toolkit'
import { takeEvery, all, call, put, debounce, takeLatest } from 'redux-saga/effects'

import {
  NotificationTaskItem,
  IGetNotificationListPayload,
  FloralManagerItem,
  IGetOverviewProgress,
  IGetOverviewProgressPayload,
  INotificationDetail,
  IGetNotiDetailPayload,
  IMarkCompletePayload
} from 'src/common/notificationTask.interface'
import {
  getNotificationList,
  getFloralManagerForNP,
  getOverviewProgress,
  getNotiDetail,
  markTaskComplete,
  countTheNoti
} from 'src/api/notificationTask'

import { notificationTaskAction } from './notificationTask.slice'

export const notificationTaskActionSaga = {
  getNotificationListSaga: createAction<IGetNotificationListPayload>('notificationTaskSaga/getNotificationListSaga'),
  getCompleteNotificationListSaga: createAction<IGetNotificationListPayload>(
    'notificationTaskSaga/getCompleteNotificationListSaga'
  ),
  getMoreNotificationTaskSaga: createAction<IGetNotificationListPayload>(
    'notificationTaskSaga/getMoreNotificationTaskSaga'
  ),
  countTheNotiSaga: createAction<IGetNotificationListPayload>('notificationTaskSaga/countTheNotiSaga'),
  getFloralManagerForNPSaga: createAction<{}>('notificationTaskSaga/getFloralManagerForNPSaga'),
  getOverviewProgressSaga: createAction<IGetOverviewProgressPayload>('notificationTaskSaga/getOverviewProgressSaga'),
  getNotificationDetaiSaga: createAction<IGetNotiDetailPayload>('notificationTaskSaga/getNotificationDetaiSaga'),
  markNotiCompleteSaga: createAction<IMarkCompletePayload>('notificationTaskSaga/markNotiCompleteSaga'),
  setOpenConfirmModalSaga: createAction<boolean>('notificationTaskSaga/setOpenConfirmModalSaga'),
  setHasMoreIncompleteNotiSaga: createAction<boolean>('notificationTaskSaga/setHasMoreIncompleteNotiSaga'),
  setHasMoreCompleteNotiSaga: createAction<boolean>('notificationTaskSaga/setHasMoreCompleteNotiSaga')
}
export function* getNotificationTaskSaga({
  payload
}: ReturnType<typeof notificationTaskActionSaga.getNotificationListSaga>) {
  try {
    yield put(notificationTaskAction.setNotificationTaskLoading(true))
    payload.status = 'INCOMPLETE'
    const notificationTasks: NotificationTaskItem[] = yield call(getNotificationList, payload)
    yield put(notificationTaskAction.setNotificationTaskData(notificationTasks))
    yield put(notificationTaskAction.setNotificationTaskLoading(false))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getNotificationTaskSaga', error)
  }
}
export function* countTheNotiSaga({ payload }: ReturnType<typeof notificationTaskActionSaga.countTheNotiSaga>) {
  try {
    yield put(notificationTaskAction.setIncompleteNotiCountLoading(true))
    payload.status = 'INCOMPLETE'
    const taskCount: number = yield call(countTheNoti, payload)
    yield put(notificationTaskAction.setIncompleteNotiCount(taskCount))
    yield put(notificationTaskAction.setIncompleteNotiCountLoading(false))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error countTheNotiSaga', error)
  }
}

export function* getCompleteNotificationTaskSaga({
  payload
}: ReturnType<typeof notificationTaskActionSaga.getCompleteNotificationListSaga>) {
  try {
    yield put(notificationTaskAction.setCompleteNotificationTaskLoading(true))
    payload.status = 'COMPLETE'
    const completeNotificationTasks: NotificationTaskItem[] = yield call(getNotificationList, payload)
    yield put(notificationTaskAction.setCompleteNotificationTaskData(completeNotificationTasks))
    yield put(notificationTaskAction.setCompleteNotificationTaskLoading(false))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getCompleteNotificationTaskSaga', error)
  }
}

export function* getMoreNotificationTaskSaga({
  payload
}: ReturnType<typeof notificationTaskActionSaga.getMoreNotificationTaskSaga>) {
  try {
    if (payload.status === 'INCOMPLETE' || !payload.status) {
      payload.status = 'INCOMPLETE'
      yield put(notificationTaskAction.setLoadingMoreNoti(true))
      const notificationTasks: NotificationTaskItem[] = yield call(getNotificationList, payload)
      if (notificationTasks.length === 0) {
        yield put(notificationTaskAction.setHasMoreIncompleteNoti(false))
      }
      yield put(notificationTaskAction.setMoreNotificationTaskData(notificationTasks))
      yield put(notificationTaskAction.setLoadingMoreNoti(false))
    } else {
      yield put(notificationTaskAction.setLoadingMoreCompleteNoti(true))
      payload.status = 'COMPLETE'
      const completeNotificationTasks: NotificationTaskItem[] = yield call(getNotificationList, payload)
      if (completeNotificationTasks.length === 0) {
        yield put(notificationTaskAction.setHasMoreCompleteNoti(false))
      }
      yield put(notificationTaskAction.setMoreCompleteNotificationTaskData(completeNotificationTasks))
      yield put(notificationTaskAction.setLoadingMoreCompleteNoti(false))
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getMoreNotificationTaskSaga', error)
  }
}

export function* getFloralManagerForNPSaga() {
  try {
    yield put(notificationTaskAction.setLoadingFloralManager(true))
    const FloralManagerData: FloralManagerItem[] = yield call(getFloralManagerForNP)
    yield put(notificationTaskAction.setFloralManagerData(FloralManagerData))
    yield put(notificationTaskAction.setLoadingFloralManager(false))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getFloralManagerForNPSaga', error)
  }
}

export function* getOverviewProgressSaga({
  payload
}: ReturnType<typeof notificationTaskActionSaga.getOverviewProgressSaga>) {
  try {
    yield put(notificationTaskAction.setOverviewLoading(true))
    const overviewData: IGetOverviewProgress = yield call(getOverviewProgress, payload)
    yield put(notificationTaskAction.setOverviewProgressData(overviewData))
    yield put(notificationTaskAction.setOverviewLoading(false))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getOverviewProgressSaga', error)
  }
}

export function* getNotificationDetaiSaga({
  payload
}: ReturnType<typeof notificationTaskActionSaga.getNotificationDetaiSaga>) {
  try {
    yield put(notificationTaskAction.setNotiDetailLoading(true))
    const notiDetailData: INotificationDetail = yield call(getNotiDetail, payload)
    yield put(notificationTaskAction.setSummaryEventData(notiDetailData.summaryEventData))
    yield put(notificationTaskAction.setNotificationDetail(notiDetailData.notificationDetail?.notificationTasks || []))
    yield put(notificationTaskAction.setRedirectData(notiDetailData.redirectData || []))
    yield put(notificationTaskAction.setNotiDetailLoading(false))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getNotificationDetaiSaga', error)
  }
}

export function* markNotiCompleteSaga({ payload }: ReturnType<typeof notificationTaskActionSaga.markNotiCompleteSaga>) {
  try {
    yield put(notificationTaskAction.setIsMarking(true))
    yield put(notificationTaskAction.setModalConfirmOpen(false))

    yield call(markTaskComplete, payload)

    const notiDetailData: INotificationDetail = yield call(getNotiDetail, { eventId: payload.eventId })
    yield put(notificationTaskAction.setSummaryEventData(notiDetailData.summaryEventData))
    yield put(notificationTaskAction.setNotificationDetail(notiDetailData.notificationDetail?.notificationTasks || []))

    yield put(notificationTaskAction.setIsMarking(false))
    yield put(notificationTaskAction.setSpecificNotificationTask({ eventId: payload.eventId, notiId: payload.notiId }))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error markNotiCompleteSaga', error)
  }
}

export function* setOpenConfirmModalSaga({
  payload
}: ReturnType<typeof notificationTaskActionSaga.setOpenConfirmModalSaga>) {
  try {
    yield put(notificationTaskAction.setModalConfirmOpen(payload))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error setOpenConfirmModalSaga', error)
  }
}

export function* setHasMoreIncompleteNotiSaga({
  payload
}: ReturnType<typeof notificationTaskActionSaga.setHasMoreIncompleteNotiSaga>) {
  try {
    yield put(notificationTaskAction.setHasMoreIncompleteNoti(payload))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error setHasMoreIncompleteNotiSaga', error)
  }
}

export function* setHasMoreCompleteNotiSaga({
  payload
}: ReturnType<typeof notificationTaskActionSaga.setHasMoreCompleteNotiSaga>) {
  try {
    yield put(notificationTaskAction.setHasMoreCompleteNoti(payload))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error setHasMoreCompleteNotiSaga', error)
  }
}

export default [
  function* fetchWatcher() {
    yield all([takeEvery(notificationTaskActionSaga.getMoreNotificationTaskSaga, getMoreNotificationTaskSaga)])
    yield all([debounce(800, notificationTaskActionSaga.getNotificationListSaga, getNotificationTaskSaga)])
    yield all([debounce(800, notificationTaskActionSaga.countTheNotiSaga, countTheNotiSaga)])
    yield all([
      debounce(800, notificationTaskActionSaga.getCompleteNotificationListSaga, getCompleteNotificationTaskSaga)
    ])
    yield all([takeLatest(notificationTaskActionSaga.getFloralManagerForNPSaga, getFloralManagerForNPSaga)])
    yield all([debounce(800, notificationTaskActionSaga.getOverviewProgressSaga, getOverviewProgressSaga)])
    yield all([takeLatest(notificationTaskActionSaga.getNotificationDetaiSaga, getNotificationDetaiSaga)])
    yield all([takeEvery(notificationTaskActionSaga.markNotiCompleteSaga, markNotiCompleteSaga)])
    yield all([takeLatest(notificationTaskActionSaga.setOpenConfirmModalSaga, setOpenConfirmModalSaga)])
    yield all([takeEvery(notificationTaskActionSaga.setHasMoreIncompleteNotiSaga, setHasMoreIncompleteNotiSaga)])
    yield all([takeEvery(notificationTaskActionSaga.setHasMoreCompleteNotiSaga, setHasMoreCompleteNotiSaga)])
  }
]
