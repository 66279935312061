import { EventInfo, EventModel } from '../common/common.interface'
import { getHttp, putHttp, deleteHttp } from './apiLayer'

interface ProposalInspirationPhotosPayLoad {
  id?: string
  imageUrl: string
  isHidden?: boolean
}

interface UpdateProposalInspirationPhotosPayLoad {
  isHidden?: boolean
}

export interface ProposalPayLoad {
  id?: string
  notes: string
  internalNotes: string
  proposalInspirationPhotos: ProposalInspirationPhotosPayLoad[]
}
export interface UpdateStyleInformationPayload {
  colors: string[]
  proposals: ProposalPayLoad[]
  pinterestBoard: string
  shape: string
}

export interface AirtableUpdatePayload {
  totalLabor?: number
  designerFeeNotes?: string
}

export const getEventData = async (customerId: string | number): Promise<EventInfo | undefined> => {
  const result = await getHttp(`/getEventData/${customerId}`)
  return result.data
}

export const getStyleInformationByEventId = async (eventId: string | number): Promise<EventModel | undefined> => {
  const result = await getHttp(`/getStyleInformation/${eventId}`)
  return result.data
}

export const updateStyleInformationByEventId = async (
  eventId: string | number,
  payload: UpdateStyleInformationPayload
): Promise<EventModel | undefined> => {
  const result = await putHttp(`/updateStyleInformation/${eventId}`, payload)
  return result.data
}

export const removeProposalInspirationPhoto = async (
  proposalInspirationPhotoId: string | number
): Promise<boolean | undefined> => {
  const result = await deleteHttp(`/removeProposalInspirationPhoto/${proposalInspirationPhotoId}`)
  return result.data
}

export const updateProposalInspirationPhoto = async (
  proposalInspirationPhotoId: string | number,
  payload: UpdateProposalInspirationPhotosPayLoad
): Promise<ProposalInspirationPhotosPayLoad | undefined> => {
  const result = await putHttp(`/proposalInspirationPhoto/${proposalInspirationPhotoId}`, payload)
  return result.data
}

export const updateAirtableByEventId = async (
  eventId: string | number,
  payload: AirtableUpdatePayload
): Promise<EventModel | undefined> => {
  const result = await putHttp(`/update-airtable-by-event-id/${eventId}`, payload)
  return result.data
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const bulkCreateRecipesByEventId = async (eventId: string | number): Promise<any | undefined> => {
  const result = await getHttp(`/bulk-create-recipes/${eventId}`)
  return result.data
}

export const detectedDifferenceRecipesByEventId = async (eventId: string | number): Promise<boolean | undefined> => {
  const result = await getHttp(`/detect-difference-recipes-from-proposal/${eventId}`)
  return result.data
}
