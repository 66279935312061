import React from 'react'

import './ErrorPage.scss'

interface ErrorPageProps {
  message?: string
  xAmznRequestId: string
}

export const ErrorPage = (props: ErrorPageProps) => {
  const { xAmznRequestId, message } = props
  return (
    <div className="error">
      <div className="error-inner">
        <h1> Oops! </h1>
        <p>{message || 'Something went wrong. Please try refreshing the page.'}</p>
        {xAmznRequestId && <p>{xAmznRequestId}</p>}
      </div>
    </div>
  )
}
