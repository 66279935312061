import { fork, all } from 'redux-saga/effects'

import recipeSaga from './recipe/recipe.saga'
import orderSaga from './order/order.saga'
import templateSaga from './template/template.saga'
import stemSaga from './stem/stem.saga'
import productSaga from './product/product.saga'
import eventOrderSaga from './eventOrder/eventOrder.saga'
import hardGoodSaga from './hardGood/hardGood.saga'
import proposalTemplateSaga from './proposalTemplate/template.saga'
import catalogSaga from './productCatalog/catalog.saga'
import stemPaletteSaga from './stemPalette/stemPalette.saga'
import notificationTaskSaga from './notificationTask/notificationTask.saga'
import recipeFormulaSaga from './recipeFormula/formula.saga'

export default function* rootSaga() {
  yield all([
    ...orderSaga.map((watcher) => fork(watcher)),
    ...recipeSaga.map((watcher) => fork(watcher)),
    ...templateSaga.map((watcher) => fork(watcher)),
    ...stemSaga.map((watcher) => fork(watcher)),
    ...productSaga.map((watcher) => fork(watcher)),
    ...eventOrderSaga.map((watcher) => fork(watcher)),
    ...hardGoodSaga.map((watcher) => fork(watcher)),
    ...proposalTemplateSaga.map((watcher) => fork(watcher)),
    ...catalogSaga.map((watcher) => fork(watcher)),
    ...stemPaletteSaga.map((watcher) => fork(watcher)),
    ...notificationTaskSaga.map((watcher) => fork(watcher)),
    ...recipeFormulaSaga.map((watcher) => fork(watcher))
  ])
}
