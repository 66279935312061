/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { RouteConfig } from 'src/common/route.interface'

interface Props {
  routes?: RouteConfig[]
}

const GroupRoutes: React.FunctionComponent<Props> = (props: Props) => {
  const { routes } = props as Props
  const renderRoute = () => {
    if (!routes?.length) {
      return null
    }

    return routes.map((route, index) => {
      return <Route key={index} {...route} />
    })
  }

  return <Switch>{renderRoute()}</Switch>
}

export default GroupRoutes
