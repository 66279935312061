import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Color } from '@material-ui/lab'
import Cookies from 'js-cookie'

interface IToast {
  color: Color
  message: string
}
export interface AppState {
  isLoading: number
  isErrorPage: boolean
  toast?: IToast
  isDarkTheme: boolean
  xAmznRequestId: string
}

const initialState: AppState = {
  isLoading: 0,
  isErrorPage: false,
  isDarkTheme: Cookies.get('theme') === 'dark',
  xAmznRequestId: ''
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    startLoading: {
      reducer: (state, action: PayloadAction<number>) => {
        state.isLoading += action.payload
      },
      prepare: (count?: number) => {
        return { payload: count ?? 1 }
      }
    },

    endLoading: {
      reducer: (state, action: PayloadAction<number>) => {
        if (state.isLoading > 0) state.isLoading -= action.payload
      },
      prepare: (count?: number) => {
        return { payload: count ?? 1 }
      }
    },

    setErrorPage: (state, action: PayloadAction<boolean>) => {
      state.isErrorPage = action.payload
    },

    setXAmznRequestIdWhenErrorPage: (state, action: PayloadAction<string>) => {
      state.xAmznRequestId = action.payload
    },

    setIsDarkTheme: (state, action: PayloadAction<boolean>) => {
      state.isDarkTheme = action.payload
      Cookies.set('theme', action.payload ? 'dark' : 'light')
      document.body.classList.toggle('dark-theme')
    },

    setToast: (state, action: PayloadAction<IToast | undefined>) => {
      state.toast = action.payload
    }
  }
})

// type ex: recipe/addProductsData
export const appAction = appSlice.actions

export default appSlice.reducer
