import { IAxiosRequestCustomConfig, IAxiosResponseCustomConfig, IInterceptors } from '../type'

let userUpdatesCount = 0
const statisticsUpdatesByPath: { [pathName: string]: number } = {}

const METHOD_CHECK_UPDATE = ['delete', 'post', 'put']

const interceptors = (interceptorsLayer: IInterceptors) => {
  interceptorsLayer.request.use(async function (request: IAxiosRequestCustomConfig) {
    // Do something with response data
    if (request.method && METHOD_CHECK_UPDATE.includes(request.method?.toLocaleLowerCase() ?? '')) {
      userUpdatesCount++

      request.pathUrlRequest = window.location.pathname

      if (statisticsUpdatesByPath[window.location.pathname]) {
        statisticsUpdatesByPath[window.location.pathname]++
      } else {
        statisticsUpdatesByPath[window.location.pathname] = 1
      }
    }

    return request
  })

  interceptorsLayer.response.use(async function (response: IAxiosResponseCustomConfig) {
    // Do something with response data
    if (response.config.method && METHOD_CHECK_UPDATE.includes(response.config.method.toLocaleLowerCase())) {
      userUpdatesCount--
      statisticsUpdatesByPath[response.config.pathUrlRequest ?? '']--
    }

    return response
  })
}

export default {
  init: interceptors,
  getUserUpdatesCount: () => {
    return userUpdatesCount
  },
  getStatisticsUpdates: () => {
    return statisticsUpdatesByPath
  }
}
