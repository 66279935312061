import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RecipeFormula } from 'src/api/recipeFormula'

export interface FormulaState {
  recipeFormulas: RecipeFormula[]
  countRecipeFormula: number
  isLoadingGetRecipeFormulas: boolean
}

const initialState: FormulaState = {
  recipeFormulas: [],
  countRecipeFormula: 0,
  isLoadingGetRecipeFormulas: false
}

const formulaSlice = createSlice({
  name: 'stem',
  initialState,
  reducers: {
    setRecipeFormulasData(state, action: PayloadAction<RecipeFormula[]>) {
      state.recipeFormulas = action.payload
    },
    setMoreRecipeFormulasData(state, action: PayloadAction<RecipeFormula[]>) {
      state.recipeFormulas = state.recipeFormulas.concat(action.payload)
    },
    setUpdateOneRecipeFormula(state, action: PayloadAction<RecipeFormula>) {
      const recipeFormulasTemp = [...state.recipeFormulas]
      const index = recipeFormulasTemp.findIndex((item) => item.id === action.payload.id)
      recipeFormulasTemp[index] = action.payload
      state.recipeFormulas = recipeFormulasTemp
    },
    setCountRecipeFormulas(state, action: PayloadAction<number>) {
      state.countRecipeFormula = action.payload
    },
    setIsLoadingGetRecipeFormulas(state, action: PayloadAction<boolean>) {
      state.isLoadingGetRecipeFormulas = action.payload
    }
  }
})

export const formulaAction = formulaSlice.actions

export default formulaSlice.reducer
