import React from 'react'
import { Auth } from 'aws-amplify'
import { Button, Card } from '@material-ui/core'
import './Login.scss'
import { Navbar } from '../../components'

const handleLogin = () => {
  try {
    Auth.federatedSignIn()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('THERE WAS AN ERROR', e)
  }
}

export const Login = () => {
  return (
    <div className="login">
      <Navbar />
      <Card className="card">
        <h1 className="title"> Recipe Builder </h1>
        <p className="subhead"> The easy way to build recipes.</p>
        <p className="subhead">Brought to you by Poppy. </p>
        <Button variant="contained" color="primary" onClick={handleLogin}>
          Log In or Sign Up
        </Button>
      </Card>
    </div>
  )
}
