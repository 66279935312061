import { useSelector } from 'react-redux'
import { RootState } from '../reducers'

export const selectorStemCategoriesData = (state: RootState) => state.stem.stemCategories
export const selectorStemsData = (state: RootState) => state.stem.stems
export const selectorCountStem = (state: RootState) => state.stem.countStem
export const selectorIsLoadingGetStems = (state: RootState) => state.stem.isLoadingGetStems
export const selectorSuppliers = (state: RootState) => state.stem.suppliers
export const selectorSupplierStems = (state: RootState) => state.stem.supplierStems
export const selectorPreferredSuppliers = (state: RootState) => state.stem.preferredSuppliers

export const useSelectorStemCategoriesData = () => useSelector(selectorStemCategoriesData)
export const useSelectorStemsData = () => useSelector(selectorStemsData)
export const useSelectorCountStem = () => useSelector(selectorCountStem)
export const useSelectorIsLoadingGetStems = () => useSelector(selectorIsLoadingGetStems)
export const useSelectorSuppliers = () => useSelector(selectorSuppliers)
export const useSelectorSupplierStems = () => useSelector(selectorSupplierStems)
export const useSelectorPreferredSuppliers = () => useSelector(selectorPreferredSuppliers)
