/* eslint-disable @typescript-eslint/no-use-before-define */
import { createAction } from '@reduxjs/toolkit'
import { takeEvery, all, call, put, select } from 'redux-saga/effects'

import {
  getOrders,
  updateOrdersStem,
  initialCheckingOrders,
  createOrderWithMultiStemsForEventOrder,
  updateOrdersHardGood,
  createOrderWithMultiHardGoodsForEventOrder
} from 'src/api/orders'
import { IOrders, IOrderStemsOfOrder, LOADING_STATUS, OrderSupplier, Supplier, Stem } from 'src/common/common.interface'
import { getEventDataSaga, postOrdersSaga, selectorEventOrderId } from 'src/store/recipe'
import { appAction } from 'src/store/app'
import { getOrderSuppliersByEventOrderId } from 'src/api/orderSupplier'
import { getSupplierForGenericStem } from 'src/api/supplier'

import { orderAction } from './order.slice'

interface IRecipeCallAPI {
  eventId: number
}

interface IUpdateOrderStems {
  orderID: number
  orderStemId: number
  quantity?: number
  supplier?: string
  stemSupplierId?: number | null
  substitutes?: string
  subStem?: Stem
  isNeedReloadOrders?: boolean
}

interface IUpdateOrderHardGoods {
  orderID: number
  orderHardGoodId: number
  quantity?: number
  supplier?: string
  isNeedReloadOrders?: boolean
  hardGoodSupplierId?: number
}

export interface IUpdateOrderStem {
  id: number
  orderId: number
  stemId: number
  quantity: number
  supplier: string
  stemSupplierId: number | undefined | null
}

export interface IUpdateOrderHardGood {
  id: number
  orderId: number
  hardGoodId: number
  quantity: number
  supplier: string
  hardGoodSupplierId?: number
}

interface IUpdateMultiStemsForOrder {
  orderId: number
  orderStems: IUpdateOrderStem[]
}

interface ICreateOrderWithMultiStemsForEventOrder {
  eventOrderId: number
  orderStems: IUpdateOrderStem[]
  isNeedReloadOrders?: boolean
}

interface ICreateOrderWithMultiHardGoodsForEventOrder {
  eventOrderId: number
  orderHardGoods: IUpdateOrderHardGood[]
  isNeedReloadOrders?: boolean
}

interface IInitialCheckingOrders {
  eventOrderId: number
}
interface IResetOrders {
  eventOrderId: number
  isResetOrder: boolean
}

interface IGetOrderSupplierPayload {
  eventOrderId: number
}

function* getAllOrdersInfoSaga({ payload }: ReturnType<typeof orderActionSaga.getAllOrdersInfo>) {
  try {
    yield put(appAction.startLoading())

    yield all([call(postOrdersSaga, payload.eventId), call(getEventDataSaga, payload.eventId)])
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error postOrdersSaga', error)
  } finally {
    yield put(appAction.endLoading())
  }
}

export function* getOrdersSaga() {
  try {
    const eventOrderId: number | undefined = yield select(selectorEventOrderId)

    if (!eventOrderId) {
      throw new Error('Cannot found event order id')
    }

    const order: IOrders[] = yield call(getOrders, { params: { eventOrderId } })

    yield put(orderAction.setOrderData(order))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getOrdersSaga', error)
  }
}

function* updateOrderStemsSaga({ payload }: ReturnType<typeof orderActionSaga.updateOrderStems>) {
  try {
    yield put(orderAction.setSavingCount(LOADING_STATUS.START))
    const response: IOrderStemsOfOrder = yield call(updateOrdersStem, payload.orderID, payload.orderStemId, {
      quantity: payload.quantity ? +payload.quantity : undefined,
      supplier: payload.supplier,
      stemSupplierId: payload.stemSupplierId,
      substitutes: payload.substitutes ?? undefined,
      subStemId: payload.subStem?.id ?? undefined
    })
    yield put(
      orderAction.updateOrderStem({
        ids: [
          {
            id: response.id,
            price: response.price,
            selected: !!response.supplier,
            quantity: response.quantity,
            substitutes: response.substitutes || undefined,
            subStem: payload.subStem?.id ? payload.subStem : response.subStem || undefined
          }
        ]
      })
    )
    if (payload.isNeedReloadOrders) {
      const eventOrderId: number | undefined = yield select(selectorEventOrderId)

      yield all([call(initialCheckingOrdersSaga, { payload: { eventOrderId } as IInitialCheckingOrders, type: '' })])
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error updateOrderStemsSaga', error)
    throw error
  } finally {
    yield put(orderAction.setSavingCount(LOADING_STATUS.END))
  }
}

function* updateOrderHardGoodsSaga({ payload }: ReturnType<typeof orderActionSaga.updateOrderHardGoods>) {
  try {
    yield put(orderAction.setSavingCount(LOADING_STATUS.START))
    yield call(updateOrdersHardGood, payload.orderID, payload.orderHardGoodId, {
      quantity: payload.quantity,
      supplier: payload.supplier,
      hardGoodSupplierId: payload.hardGoodSupplierId
    })
    if (payload.isNeedReloadOrders) {
      const eventOrderId: number | undefined = yield select(selectorEventOrderId)

      yield all([call(initialCheckingOrdersSaga, { payload: { eventOrderId } as IInitialCheckingOrders, type: '' })])
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error updateOrderHardGoodsSaga', error)
    throw error
  } finally {
    yield put(orderAction.setSavingCount(LOADING_STATUS.END))
  }
}

export function* initialCheckingOrdersSaga({ payload }: ReturnType<typeof orderActionSaga.initialCheckingOrders>) {
  try {
    if (!payload.eventOrderId) {
      throw new Error('Cannot found event order id')
    }

    const order: IOrders[] = yield call(initialCheckingOrders, payload.eventOrderId)

    yield put(orderAction.setOrderData(order))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getOrdersSaga', error)
  }
}
export function* resetOrdersSaga({ payload }: ReturnType<typeof orderActionSaga.resetOrders>) {
  try {
    if (!payload.eventOrderId) {
      throw new Error('Cannot found event order id')
    }

    yield put(orderAction.setSavingCount(LOADING_STATUS.START))
    const order: IOrders[] = yield call(initialCheckingOrders, payload.eventOrderId, {
      isResetOrder: payload.isResetOrder
    })

    yield put(orderAction.setOrderData(order))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getOrdersSaga', error)
  } finally {
    yield put(orderAction.setSavingCount(LOADING_STATUS.END))
  }
}

function* createOrderWithMultiStemsForEventOrderSaga({
  payload
}: ReturnType<typeof orderActionSaga.createOrderWithMultiStemsForEventOrder>) {
  try {
    yield put(orderAction.setSavingCount(LOADING_STATUS.START))
    const response: IOrderStemsOfOrder[] = yield call(
      createOrderWithMultiStemsForEventOrder,
      payload.eventOrderId,
      payload.orderStems
    )
    yield put(
      orderAction.updateOrderStem({
        ids: response.map((os) => ({ id: os.id, selected: !!os.supplier, price: os.price, quantity: os.quantity }))
      })
    )
    if (payload.isNeedReloadOrders) {
      const eventOrderId: number | undefined = yield select(selectorEventOrderId)

      yield all([call(initialCheckingOrdersSaga, { payload: { eventOrderId } as IInitialCheckingOrders, type: '' })])
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error createOrderWithMultiStemsForEventOrderSaga', error)
    throw error
  } finally {
    yield put(orderAction.setSavingCount(LOADING_STATUS.END))
  }
}

function* createOrderWithMultiHardGoodsForEventOrderSaga({
  payload
}: ReturnType<typeof orderActionSaga.createOrderWithMultiHardGoodsForEventOrder>) {
  try {
    yield put(orderAction.setSavingCount(LOADING_STATUS.START))
    yield call(createOrderWithMultiHardGoodsForEventOrder, payload.eventOrderId, payload.orderHardGoods)
    if (payload.isNeedReloadOrders) {
      const eventOrderId: number | undefined = yield select(selectorEventOrderId)

      yield all([call(initialCheckingOrdersSaga, { payload: { eventOrderId } as IInitialCheckingOrders, type: '' })])
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error createOrderWithMultiHardGoodsForEventOrderSaga', error)
    throw error
  } finally {
    yield put(orderAction.setSavingCount(LOADING_STATUS.END))
  }
}

export function* listAllSupplier() {
  try {
    const suppliers: Supplier[] = yield call(getSupplierForGenericStem)

    yield put(orderAction.setSupplierForGenericStem(suppliers))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error listAllSupplier', error)
  }
}

export function* getOrderSuppliersByEventOrderIdSaga({
  payload
}: ReturnType<typeof orderActionSaga.getOrderSuppliersByEventOrderId>) {
  try {
    const orderSuppliers: OrderSupplier[] = yield call(getOrderSuppliersByEventOrderId, payload.eventOrderId)

    yield put(orderAction.setOrderSuppliers(orderSuppliers))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error listAllSupplier', error)
  }
}

export const orderActionSaga = {
  getOrders: createAction('orderActionSaga/getOrders'),
  getAllOrdersInfo: createAction<IRecipeCallAPI>('orderActionSaga/getAllOrdersInfo'),
  updateOrderStems: createAction<IUpdateOrderStems>('orderActionSaga/updateOrderStems'),
  updateOrderHardGoods: createAction<IUpdateOrderHardGoods>('orderActionSaga/updateOrderHardGoods'),
  initialCheckingOrders: createAction<IInitialCheckingOrders>('orderActionSaga/initialCheckingOrders'),
  resetOrders: createAction<IResetOrders>('orderActionSaga/resetOrders'),
  updateMultiStemsForOrder: createAction<IUpdateMultiStemsForOrder>('orderActionSaga/updateMultiStemsForOrder'),
  createOrderWithMultiStemsForEventOrder: createAction<ICreateOrderWithMultiStemsForEventOrder>(
    'orderActionSaga/createOrderWithMultiStemsForEventOrder'
  ),
  createOrderWithMultiHardGoodsForEventOrder: createAction<ICreateOrderWithMultiHardGoodsForEventOrder>(
    'orderActionSaga/createOrderWithMultiHardGoodsForEventOrder'
  ),
  listAllSupplier: createAction('orderActionSaga/listAllSupplier'),
  getOrderSuppliersByEventOrderId: createAction<IGetOrderSupplierPayload>(
    'orderActionSaga/getOrderSuppliersByEventOrderId'
  )
}

export default [
  function* fetchWatcher() {
    yield all([
      takeEvery(orderActionSaga.getAllOrdersInfo, getAllOrdersInfoSaga),
      takeEvery(orderActionSaga.getOrders, getOrdersSaga),
      takeEvery(orderActionSaga.updateOrderStems, updateOrderStemsSaga),
      takeEvery(orderActionSaga.updateOrderHardGoods, updateOrderHardGoodsSaga),
      takeEvery(orderActionSaga.initialCheckingOrders, initialCheckingOrdersSaga),
      takeEvery(orderActionSaga.resetOrders, resetOrdersSaga),
      takeEvery(orderActionSaga.createOrderWithMultiStemsForEventOrder, createOrderWithMultiStemsForEventOrderSaga),
      takeEvery(
        orderActionSaga.createOrderWithMultiHardGoodsForEventOrder,
        createOrderWithMultiHardGoodsForEventOrderSaga
      ),
      takeEvery(orderActionSaga.listAllSupplier, listAllSupplier),
      takeEvery(orderActionSaga.getOrderSuppliersByEventOrderId, getOrderSuppliersByEventOrderIdSaga)
    ])
  }
]
