import { RecipeFormulaSize, RecipeFormulaStemRole, RecipeFormulaStyle } from 'src/common/enum'
import { Stem } from 'src/common/common.interface'
import { getHttp, postHttp, putHttp } from './apiLayer'
import { ProductType } from './productType'

export interface IGetListRecipeFormulasPayload {
  q?: string
  productType?: string
  style?: string
  size?: string
  color?: string
  sortName?: string
  sortType?: string
  page?: number
  limit?: number
  isAll?: boolean
}

export interface RecipeFormulaStem {
  id?: number
  recipeFormulaId: number
  role: RecipeFormulaStemRole | null
  roleIndex: number | null
  sequenceNum: number | null
  quantity: number | null
  stem?: Stem
}

export interface RecipeFormula {
  id?: number
  productTypeId: number
  productType?: ProductType
  style: RecipeFormulaStyle | null
  size: RecipeFormulaSize | null
  colorComplexity: number | null
  name: string | null
  recipeFormulaStems: RecipeFormulaStem[]
  updatedAt?: string
}

export interface IRecipeFormulaStemPayload {
  role: RecipeFormulaStemRole | null
  roleIndex: number | null
  sequenceNum: number | null
  quantity: number | null
}

export interface IRecipeFormulaPayload {
  name: string
  productTypeId: number
  style: RecipeFormulaStyle | null
  size: RecipeFormulaSize | null
  colorComplexity: number
  recipeFormulaStems: IRecipeFormulaStemPayload[]
}

export interface IUpdateRecipeFormulaStemPayload {
  id?: number
  role: RecipeFormulaStemRole | null
  roleIndex: number | null
  sequenceNum: number | null
  quantity: number | null
}

export interface IUpdateRecipeFormulaPayload {
  name: string
  productTypeId: number
  style: RecipeFormulaStyle | null
  size: RecipeFormulaSize | null
  colorComplexity: number
  recipeFormulaStems: IUpdateRecipeFormulaStemPayload[]
}

export const getAllRecipeFormulas = async (): Promise<RecipeFormula[]> => {
  const result = await getHttp(`recipe-formulas/get-all`)
  return result.data
}

export const getListRecipeFormulas = async (payload: IGetListRecipeFormulasPayload): Promise<RecipeFormula[]> => {
  const params = { ...payload }
  params.q = encodeURIComponent(payload.q || '')
  const result = await getHttp(`recipe-formulas`, { params })
  return result.data
}

export const createRecipeFormula = async (payload: IRecipeFormulaPayload): Promise<RecipeFormula> => {
  const result = await postHttp(`recipe-formulas`, payload)
  return result.data
}

export const getRecipeFormulaDetail = async (recipeFormulaId: number): Promise<RecipeFormula> => {
  const result = await getHttp(`recipe-formulas/${recipeFormulaId}`)
  return result.data
}

export const updateRecipeFormula = async (
  recipeFormulaId: number,
  payload: IUpdateRecipeFormulaPayload
): Promise<RecipeFormula> => {
  const result = await putHttp(`recipe-formulas/${recipeFormulaId}`, payload)
  return result.data
}
