import React from 'react'

import { Button, Tooltip } from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { COPY_ELM_ID, renderCopyData } from 'src/shared/utils/copy'
import { copyElementToClipboard } from 'src/common/util'

interface Props {
  dataCopyComponent?: JSX.Element
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  propsBtn?: any
  children?: React.ReactNode
  copyText?: string
}

export const CopyButton = (props: Props) => {
  const { dataCopyComponent, copyText } = props
  const [showTooltip, setShowTooltip] = React.useState(false)

  const handleTooltipClose = () => {
    setShowTooltip(false)
  }

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    if (dataCopyComponent) {
      renderCopyData(dataCopyComponent)

      setTimeout(() => {
        const isSuccess = copyElementToClipboard(COPY_ELM_ID)

        if (isSuccess) {
          setShowTooltip(true)
          setTimeout(() => {
            setShowTooltip(false)
          }, 1500)
        } else {
          // eslint-disable-next-line no-alert
          window.alert('copy failed')
        }
      }, 1)
    } else if (copyText) {
      navigator.clipboard.writeText(copyText)
    }
  }

  return (
    <Tooltip
      onClose={handleTooltipClose}
      open={showTooltip}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      placement="right"
      title="Copied"
    >
      <Button variant="outlined" color="default" {...props.propsBtn} onClick={handleClick}>
        {props.children ? props.children : <FileCopyIcon />}
      </Button>
    </Tooltip>
  )
}
