import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HardGood } from 'src/common/common.interface'

export interface HardGoodState {
  hardGoods: HardGood[]
  countHardGood: number
}

const initialState: HardGoodState = {
  hardGoods: [],
  countHardGood: 0
}

const hardGoodSlice = createSlice({
  name: 'hardGood',
  initialState,
  reducers: {
    setHardGoodsData(state, action: PayloadAction<HardGood[]>) {
      state.hardGoods = action.payload
    },
    setMoreHardGoodsData(state, action: PayloadAction<HardGood[]>) {
      state.hardGoods = state.hardGoods.concat(action.payload)
    },
    removeStemFromList(state, action: PayloadAction<HardGood>) {
      const hardGoodsTemp = [...state.hardGoods]
      const indexHardGood = hardGoodsTemp.findIndex((hg) => hg.id === action.payload.id)
      if (indexHardGood > -1) {
        state.hardGoods.splice(indexHardGood, 1)
      }
    },
    setCountHardGood(state, action: PayloadAction<number>) {
      state.countHardGood = action.payload
    },
    add1HardGoodData(state, action: PayloadAction<HardGood>) {
      state.hardGoods.unshift({ ...action.payload })
    },
    update1HardGoodData(state, action: PayloadAction<HardGood>) {
      const hardGoodsTemp = [...state.hardGoods]
      const index = hardGoodsTemp.findIndex((hg) => hg.id === action.payload.id)
      if (index > -1) {
        hardGoodsTemp[index] = action.payload
        state.hardGoods = [...hardGoodsTemp]
      }
    }
  }
})

export const hardGoodAction = hardGoodSlice.actions

export default hardGoodSlice.reducer
