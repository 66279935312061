import { EventOrder, EventOrderStem, UpdateEventStemsPayload } from 'src/common/common.interface'
import { deleteHttp, getHttp, postHttp, putHttp } from './apiLayer'

export interface MultiEventOrderStemPayload {
  eventOrderStemId: number
  sequenceNum?: number
}
export interface UpdateMultiEventOrderStem {
  eventOrderStems: MultiEventOrderStemPayload[]
}

export const getEventSummary = async (eventId: number): Promise<EventOrder> => {
  const data = await getHttp(`eventOrder/${eventId}/summary`)
  return data?.data
}

export const getListEventOrderStems = async (eventOrderId: number): Promise<EventOrder> => {
  const data = await getHttp(`eventOrderStem/${eventOrderId}/event-stems`)
  return data?.data
}

export const addEventOrderStem = async (eventOrderId: number, stemId: number): Promise<EventOrderStem> => {
  const data = await postHttp(`eventOrderStem/${eventOrderId}/event-stems/${stemId}`)
  return data?.data
}

export const deleteEventOrderStem = async (eventOrderId: number, stemId: number): Promise<EventOrderStem> => {
  const data = await deleteHttp(`eventOrderStem/${eventOrderId}/event-stems/${stemId}`)
  return data?.data
}

export const updateMultiEventOrderStems = async (payload: UpdateMultiEventOrderStem): Promise<EventOrderStem[]> => {
  const data = await putHttp(`eventOrderStem/multiples`, payload)
  return data?.data
}

export const updateEventStems = async (
  eventOrderId: number,
  payload: UpdateEventStemsPayload
): Promise<EventOrderStem[]> => {
  const data = await putHttp(`eventOrders/${eventOrderId}/event-stems`, payload)
  return data?.data
}
