import React, { useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as PoppyLogo } from 'src/assets/images/Poppy_logo.svg'
import { ReactComponent as TextLogo } from 'src/assets/images/Text_logo_fulfillment.svg'
import './Navbar.scss'
import { Auth } from 'aws-amplify'
import { Button, FormControlLabel, FormGroup, Menu, MenuItem, Switch } from '@material-ui/core'
import { appAction, selectorIsDarkTheme } from 'src/store/app'
import { PATH_URL } from '../../common/path'
import { UserContext } from '../../shared/context'

export interface INavbarProps {
  id?: string
  className?: string
  children?: React.ReactNode
}

export const Navbar = (props: INavbarProps) => {
  const user = useContext(UserContext)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const history = useHistory()

  const dispatch = useDispatch()
  const isDarkTheme = useSelector(selectorIsDarkTheme)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <div className={props.className ? props.className : 'navbar'} id={props.id ? props.id : 'navbar'}>
      {/* <img
        src="https://poppy-common-assets.s3.amazonaws.com/poppy-internal-logo.png"
        alt="Poppy Logo"
        onClick={() => {
          user && history.push(PATH_URL.HOME)
        }}
      /> */}
      <div
        className="navbar-logo"
        onClick={() => {
          user && history.push(PATH_URL.HOME)
        }}
      >
        <PoppyLogo />
        <TextLogo />
      </div>

      {props.children}
      {user && (
        <div>
          <Button
            variant="text"
            // color="primary"
            aria-controls="nav-dropdown-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            Menu
          </Button>
          <Menu id="nav-dropdown-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem onClick={handleClose}>
              <Link to={PATH_URL.HOME} className="menu-item">
                Home
              </Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link to={PATH_URL.PROFILE} className="menu-item">
                Profile
              </Link>
            </MenuItem>
            {/* <MenuItem onClick={handleClose}>
              <Link to={PATH_URL.TEMPLATE} className="menu-item">
                Template
              </Link>
            </MenuItem> */}

            <MenuItem onClick={handleClose}>
              <Link to={PATH_URL.STEMS} className="menu-item">
                Stems
              </Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link to={PATH_URL.STEM_PALETTE} className="menu-item">
                Stem Palettes
              </Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link to={PATH_URL.HARD_GOODS} className="menu-item">
                Hard Goods
              </Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link to={PATH_URL.PRODUCTS} className="menu-item">
                Products
              </Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link to={PATH_URL.PROPOSAL_TEMPLATE} className="menu-item">
                Proposal Template
              </Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link to={PATH_URL.NOTIFICATION_TASK} className="menu-item">
                Notification Task
              </Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link to={PATH_URL.RECIPE_FORMULA} className="menu-item">
                Recipe Formulas
              </Link>
            </MenuItem>
            {/* <MenuItem onClick={handleClose}>
              <Link to={PATH_URL.ORDER_SUMMARY} className="menu-item">
                Order Summary
              </Link>
            </MenuItem> */}

            <MenuItem>
              <FormGroup className="menu-item">
                <FormControlLabel
                  control={
                    <Switch checked={isDarkTheme} onChange={() => dispatch(appAction.setIsDarkTheme(!isDarkTheme))} />
                  }
                  label="Toggle  Dark Mode"
                />
              </FormGroup>
            </MenuItem>
            <MenuItem onClick={() => Auth.signOut()}>
              <span className="menu-item">Logout</span>
            </MenuItem>
          </Menu>
        </div>
      )}
    </div>
  )
}
