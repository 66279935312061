/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance } from 'axios'
import { Auth } from 'aws-amplify'

import { SentryCaptureAPI } from 'src/common/sentry'
import { appAction } from 'src/store/app'
import configureStore from 'src/store/configureStore'

import { CAN_NOT_CREATE_SHIP_STATION_ORDER } from 'src/common/constants'
import { ENV } from '../config/env'
import updatesCountInterceptors from './interceptors/UpdatesCount.interceptors'
import { IAxiosRequestCustomConfig } from './type'

const recipeBuilderBackend = axios.create({
  baseURL: ENV.RECIPE_BUILDER_API_URL,
  headers: {
    'content-type': 'application/json'
  }
})

recipeBuilderBackend.interceptors.request.use(async function (request: IAxiosRequestCustomConfig) {
  // Do something with response data
  if (request.isNotAuthorization) {
    request = {
      ...(request || {}),
      headers: {
        ...(request.headers || ({} as any)),
        Authorization: undefined
      }
    }
  } else {
    request = {
      ...(request || {}),
      headers: {
        ...(request.headers || ({} as any)),
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      }
    }
    // request.headers.Authorization = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
  }

  return request
})

// Add a response interceptor
recipeBuilderBackend.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    // Do something with response error
    SentryCaptureAPI(error)
    return Promise.reject(error.response ?? error)
  }
)

// eslint-disable-next-line @typescript-eslint/no-shadow
export const configInterceptorsWithRedux = (store: ReturnType<typeof configureStore>) => {
  // Add a response interceptor
  recipeBuilderBackend.interceptors.response.use(
    function (response) {
      // Do something with response data
      return response
    },
    function (error) {
      if (error?.data?.code !== CAN_NOT_CREATE_SHIP_STATION_ORDER) {
        store.dispatch(appAction.setErrorPage(true))
      }
      store.dispatch(appAction.setXAmznRequestIdWhenErrorPage(error?.data?.xAmznRequestId || ''))

      return Promise.reject(error)
    }
  )
}

const registerInterceptors = (axiosInst: AxiosInstance) => {
  updatesCountInterceptors.init(axiosInst.interceptors)
}

registerInterceptors(recipeBuilderBackend)

export const getHttp = async (url: string, config?: IAxiosRequestCustomConfig | undefined) => {
  const response = await recipeBuilderBackend.get(url, config)
  return response.data
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postHttp = async (url: string, data?: any, config?: IAxiosRequestCustomConfig | undefined) => {
  const response = await recipeBuilderBackend.post(url, data, config)
  return response.data
}

export const deleteHttp = async (url: string, config?: IAxiosRequestCustomConfig | undefined) => {
  const response = await recipeBuilderBackend.delete(url, config)
  return response.data
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const putHttp = async (url: string, data?: any, config?: IAxiosRequestCustomConfig | undefined) => {
  const response = await recipeBuilderBackend.put(url, data, config)
  return response.data
}
export const patchHttp = async (url: string, data?: any, config?: IAxiosRequestCustomConfig | undefined) => {
  const response = await recipeBuilderBackend.patch(url, data, config)
  return response.data
}

export const uploadFileHttp = async (
  url: string,
  data?: any,
  config?: IAxiosRequestCustomConfig | undefined,
  onProgress?: (percent: number) => void
) => {
  const headers = { 'Content-Type': 'multipart/form-data' }
  const onUploadProgress = (progressEvent: any) => {
    const { loaded, total } = progressEvent

    const percent = Math.round((loaded / (total ?? 1)) * 100)
    if (typeof onProgress === 'function') {
      onProgress(percent)
    }
  }
  const configs: IAxiosRequestCustomConfig = { ...config, onUploadProgress, headers }
  const response = await recipeBuilderBackend.post(url, data, configs)
  return response.data
}

export default recipeBuilderBackend
