/* eslint-disable @typescript-eslint/no-use-before-define */
import { createAction } from '@reduxjs/toolkit'
import { takeEvery, all, call, put } from 'redux-saga/effects'
import { getEventSummary, getListEventOrderStems } from 'src/api/eventOrder'

import { EventInfo, EventOrder, EventOrderStem, Invoice, ProductImage, Proposal } from 'src/common/common.interface'

import { IAirtableLogistic } from 'src/common/event.interface'
import { eventOrderAction } from './eventOrder.slice'

export function* getEventSummarySaga({ payload: eventId }: ReturnType<typeof eventOrderActionSaga.getEventSummary>) {
  try {
    const data: {
      eventOrder: EventOrder
      eventInfo: EventInfo
      eventProductImages: ProductImage[]
      invoice: Invoice
      proposal: Proposal
      logistics: IAirtableLogistic
    } = yield call(getEventSummary, eventId)

    yield put(eventOrderAction.setEventOrderData(data?.eventOrder))
    yield put(eventOrderAction.setEventInfoData(data?.eventInfo))
    yield put(eventOrderAction.setEventProductImages(data?.eventProductImages))
    yield put(eventOrderAction.setResultPhotos(data?.eventOrder))
    yield put(eventOrderAction.setInvoiceData(data?.invoice))
    yield put(eventOrderAction.setProposalData(data?.proposal))
    yield put(eventOrderAction.setLogisticsData(data?.logistics))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getEventSummarySaga', error)
  }
}

export function* getListEventOrderStemsSaga({
  payload: eventOrderId
}: ReturnType<typeof eventOrderActionSaga.getEventSummary>) {
  try {
    const data: EventOrderStem[] = yield call(getListEventOrderStems, eventOrderId)

    yield put(eventOrderAction.setEventOrderStems(data))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getListEventOrderStemsSaga', error)
  }
}

export const eventOrderActionSaga = {
  getEventSummary: createAction<number>('eventOrderActionSaga/getEventSummary'),
  getListEventOrderStems: createAction<number>('eventOrderActionSaga/getListEventOrderStems')
}

export default [
  function* fetchWatcher() {
    yield all([
      takeEvery(eventOrderActionSaga.getEventSummary, getEventSummarySaga),
      takeEvery(eventOrderActionSaga.getListEventOrderStems, getListEventOrderStemsSaga)
    ])
  }
]
