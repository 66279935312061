import { createContext, useContext } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const UserContext = createContext<any>({})

export function useUserContext() {
  const userContext = useContext(UserContext)
  if (!userContext) {
    throw new Error('useUserContext must be used within the UserContext.Provider')
  }

  return userContext
}
