/* eslint-disable @typescript-eslint/no-use-before-define */
import { createAction } from '@reduxjs/toolkit'
import { takeEvery, all, call, put, debounce } from 'redux-saga/effects'

import { getListHardGoods, IGetHardGoodPayload } from 'src/api/hardGood'
import { HardGood } from 'src/common/common.interface'

import { hardGoodAction } from './hardGood.slice'

export function* getHardGoodsSaga({ payload }: ReturnType<typeof hardGoodActionSaga.getListHardGoods>) {
  try {
    const hardGoods: { result: HardGood[]; count: number } = yield call(getListHardGoods, payload)

    yield put(hardGoodAction.setHardGoodsData(hardGoods.result))
    yield put(hardGoodAction.setCountHardGood(hardGoods.count))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getHardGoodsSaga', error)
  }
}

export function* getMoreHardGoodsSaga({ payload }: ReturnType<typeof hardGoodActionSaga.getListHardGoods>) {
  try {
    const hardGoods: { result: HardGood[]; count: number } = yield call(getListHardGoods, payload)

    yield put(hardGoodAction.setMoreHardGoodsData(hardGoods.result))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getMoreHardGoodsSaga', error)
  }
}

export const hardGoodActionSaga = {
  getListHardGoods: createAction<IGetHardGoodPayload>('stemActionSaga/getListHardGoods'),
  getMoreListHardGoods: createAction<IGetHardGoodPayload>('stemActionSaga/getMoreListHardGoods')
}

export default [
  function* fetchWatcher() {
    yield all([takeEvery(hardGoodActionSaga.getMoreListHardGoods, getMoreHardGoodsSaga)])
    yield all([debounce(500, hardGoodActionSaga.getListHardGoods, getHardGoodsSaga)])
  }
]
