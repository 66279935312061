import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  EventInfo,
  EventOrder,
  EventOrderStem,
  Invoice,
  IOrders,
  IOrderStemsOfOrder,
  OrderHardGood,
  ProductImage,
  Proposal,
  RecipeModel,
  StemOrderUnit
} from 'src/common/common.interface'
import { ProductImageType } from 'src/common/enum'
import { IAirtableLogistic } from 'src/common/event.interface'

export interface EventOrderState {
  eventOrder?: EventOrder
  eventInfo?: EventInfo
  eventProductImages: ProductImage[]
  eventOrderStems: EventOrderStem[]
  resultPhotos: ProductImage[]
  viewRecipe?: RecipeModel
  viewOrder?: IOrders
  invoice?: Invoice
  proposal?: Proposal
  logistics?: IAirtableLogistic
  stemsOfRecipeFormula?: StemOrderUnit[]
}

const initialState: EventOrderState = {
  eventOrder: undefined,
  eventInfo: undefined,
  eventProductImages: [],
  eventOrderStems: [],
  resultPhotos: [],
  stemsOfRecipeFormula: undefined
}

const eventOrderSlice = createSlice({
  name: 'eventOrder',
  initialState,
  reducers: {
    setEventOrderData(state, action: PayloadAction<EventOrder>) {
      // Order recipes by sequenceNum
      let recipesDataTemp = [...action.payload.recipes]
      recipesDataTemp = recipesDataTemp.sort((a, b) => {
        const varOne = a.sequenceNum === null ? -1 : a.sequenceNum
        const varTwo = b.sequenceNum === null ? -1 : b.sequenceNum
        return varOne > varTwo ? 1 : varOne < varTwo ? -1 : 0
      })
      // Sort orders by stem then hardgood
      const ordersTemp = action.payload.orders.sort((a, b) =>
        a.orderStems?.length && b.orderStems?.length
          ? 0
          : a.orderStems?.length > 0
          ? -1
          : b.orderStems?.length > 0
          ? 1
          : 0
      )
      state.eventOrder = { ...action.payload, recipes: recipesDataTemp, orders: ordersTemp }
      state.viewRecipe = recipesDataTemp.length ? recipesDataTemp[0] : undefined
      state.viewOrder = action.payload.orders.length ? action.payload.orders[0] : undefined
    },
    setEventInfoData(state, action: PayloadAction<EventInfo>) {
      state.eventInfo = { ...action.payload, designerRate: +action.payload.designerRate }
    },
    setEventProductImages(state, action: PayloadAction<ProductImage[]>) {
      state.eventProductImages = action.payload
    },
    setInvoiceData(state, action: PayloadAction<Invoice>) {
      state.invoice = action.payload
    },
    setProposalData(state, action: PayloadAction<Proposal>) {
      state.proposal = action.payload
    },
    setLogisticsData(state, action: PayloadAction<IAirtableLogistic>) {
      state.logistics = action.payload
    },
    addMoreEventProductImage(state, action: PayloadAction<ProductImage[]>) {
      state.eventProductImages = [...state.eventProductImages].concat(action.payload)
    },
    setEventOrderStems(state, action: PayloadAction<EventOrderStem[]>) {
      state.eventOrderStems = action.payload
    },
    setResultPhotos(state, action: PayloadAction<EventOrder>) {
      if (action.payload?.recipes?.length) {
        const allProducts = action.payload?.recipes.map((r) => r.product)
        const resultPhotosTemp: ProductImage[] = []
        allProducts.forEach((p) => {
          const images = p.images
            .filter((i) => i.type === ProductImageType.RESULT && i.eventId === action.payload.eventId)
            .map((i) => ({ ...i, product: { id: p.id }, eventId: action.payload.eventId } as ProductImage))
          resultPhotosTemp.push(...images)
        })
        state.resultPhotos = resultPhotosTemp
      }
    },
    setViewRecipe(state, action: PayloadAction<RecipeModel>) {
      state.viewRecipe = action.payload
    },
    addResultPhotos(state, action: PayloadAction<ProductImage[]>) {
      state.resultPhotos = [...state.resultPhotos].concat(action.payload)
    },
    setViewOrder(state, action: PayloadAction<IOrders>) {
      state.viewOrder = action.payload
    },
    updateOrderInfo(state, action: PayloadAction<IOrders>) {
      if (state.eventOrder) {
        const newEventOrder = { ...state.eventOrder }
        if (newEventOrder.orders.map((o) => o.id).includes(action.payload.id)) {
          // If the order exists
          for (let index = 0; index < newEventOrder.orders.length; index++) {
            const order = newEventOrder.orders[index]
            if (order.id === action.payload.id) {
              newEventOrder.orders[index] = { ...order, ...action.payload }
              break
            }
          }
        } else {
          // If the order doesn't exist, add it
          newEventOrder.orders.push(action.payload)
        }

        state.eventOrder = newEventOrder
      }
      state.viewOrder = { ...state.viewOrder, ...action.payload }
    },
    updateOrderStemPrice(state, action: PayloadAction<{ order: IOrders; stemOrder: IOrderStemsOfOrder }>) {
      const newOrder = action.payload.order
      const newStemOrder = action.payload.stemOrder
      if (state.eventOrder) {
        const newEventOrder = { ...state.eventOrder }
        for (let index = 0; index < newEventOrder.orders.length; index++) {
          const order = newEventOrder.orders[index]
          if (order.id === newOrder.id) {
            newEventOrder.orders[index] = {
              ...order,
              projectedFreightTotal: newOrder.projectedFreightTotal,
              projectedProductTotal: newOrder.projectedProductTotal,
              projectedTotal: newOrder.projectedTotal
            }
            const stemOrderIndex = order.orderStems.findIndex((os) => os.id === newStemOrder.id)
            if (stemOrderIndex >= 0) {
              newEventOrder.orders[index].orderStems[stemOrderIndex].price = newStemOrder.price
            }
            state.viewOrder = { ...state.viewOrder, ...newEventOrder.orders[index] }
            break
          }
        }
        state.eventOrder = newEventOrder
      }
    },
    updateOrderHardGoodPrice(state, action: PayloadAction<{ order: IOrders; orderHardGood: OrderHardGood }>) {
      const newOrder = action.payload.order
      const newOrderHardGood = action.payload.orderHardGood
      if (state.eventOrder) {
        const newEventOrder = { ...state.eventOrder }
        for (let index = 0; index < newEventOrder.orders.length; index++) {
          const order = newEventOrder.orders[index]
          if (order.id === newOrder.id) {
            newEventOrder.orders[index] = {
              ...order,
              projectedFreightTotal: newOrder.projectedFreightTotal,
              projectedProductTotal: newOrder.projectedProductTotal,
              projectedTotal: newOrder.projectedTotal
            }
            const orderHardGoodIndex = order.orderHardGoods.findIndex((os) => os.id === newOrderHardGood.id)
            if (orderHardGoodIndex >= 0) {
              newEventOrder.orders[index].orderHardGoods[orderHardGoodIndex].price = newOrderHardGood.price
            }
            state.viewOrder = { ...state.viewOrder, ...newEventOrder.orders[index] }
            break
          }
        }
        state.eventOrder = newEventOrder
      }
    },
    setStemsOfRecipeFormula(state, action: PayloadAction<StemOrderUnit[]>) {
      state.stemsOfRecipeFormula = action.payload
    }
  }
})

export const eventOrderAction = eventOrderSlice.actions

export default eventOrderSlice.reducer
