import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  IOrders,
  LOADING_STATUS,
  OrderHardGood,
  OrderSupplier,
  Stem,
  StemSubstitute,
  Supplier
} from 'src/common/common.interface'

export interface ITemplateStem extends Stem {
  quantity: number
}

export interface OrderState {
  ordersData?: IOrders[]
  savingCount: number
  supplierForGenericStem: Supplier[]
  orderSuppliers: OrderSupplier[]
  orderHardGoods: OrderHardGood[]
}

export interface IOrderStemUpdate {
  id: number
  selected?: boolean
  price?: string
  quantity?: number
  substitutes?: string
  subStem?: Stem
  stemSubstitutes?: StemSubstitute[]
}

export interface IOrderStemSUpdate {
  ids: IOrderStemUpdate[]
}

export interface IOrderHardGoodUpdate {
  id: number
  selected: boolean
}

export interface IOrderHardGoodsUpdate {
  ids: IOrderHardGoodUpdate[]
}

export interface IOrderUpdateDeliveryDate {
  index: number
  deliveryDate: Date
}

export interface IOrderUpdateOrderUrl {
  index: number
  orderUrl: string
}

export interface IOrderHardGoodUpdateSupplierId {
  orderIndex: number
  index: number
  supplierId: string
}

export interface IOrderHardGoodUpdateQuantity {
  orderIndex: number
  index: number
  quantity: number
}

export interface IOrderHardGoodUpdatePrice {
  orderIndex: number
  index: number
  price: number
}

const initialState: OrderState = {
  ordersData: undefined,
  supplierForGenericStem: [],
  savingCount: 0,
  orderSuppliers: [],
  orderHardGoods: []
}

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrderData(state, action: PayloadAction<IOrders[]>) {
      action.payload.forEach((o) => {
        o.orderStems = o.orderStems.map((ost) => {
          return {
            ...ost,
            selected: false
          }
        })
        o.orderHardGoods = o.orderHardGoods?.map((ohg) => {
          return {
            ...ohg,
            selected: false
          }
        })
      })
      state.ordersData = action.payload
      state.orderHardGoods = action.payload.flatMap((o) => o.orderHardGoods || [])
    },
    updateOrdersHardGoodData(
      state,
      action: PayloadAction<{ orderHardGoodId: number; quantity?: number; updating?: boolean }>
    ) {
      const hardGoodIndex = state.orderHardGoods.findIndex((ohg) => ohg.id === action.payload.orderHardGoodId)
      if (hardGoodIndex !== -1) {
        if (action.payload.quantity !== undefined) {
          state.orderHardGoods[hardGoodIndex].quantity = action.payload.quantity
        }
        if (action.payload.updating !== undefined) {
          state.orderHardGoods[hardGoodIndex].updating = action.payload.updating
        }
      }
    },
    setOnlyCopiedOrderData(state, action: PayloadAction<IOrders[]>) {
      action.payload.forEach((o) => {
        o.orderStems = o.orderStems.map((ost) => {
          return {
            ...ost,
            selected: false
          }
        })
        o.orderHardGoods = o.orderHardGoods?.map((ohg) => {
          return {
            ...ohg,
            selected: false
          }
        })
      })
    },
    update1OrderDeliveryDate(state, action: PayloadAction<IOrderUpdateDeliveryDate>) {
      const orderDataTemp = [...(state.ordersData || [])]
      orderDataTemp[action.payload.index].deliveryDate = action.payload.deliveryDate
      state.ordersData = orderDataTemp
    },
    update1OrderOrderUrl(state, action: PayloadAction<IOrderUpdateOrderUrl>) {
      const orderDataTemp = [...(state.ordersData || [])]
      orderDataTemp[action.payload.index].orderUrl = action.payload.orderUrl
      state.ordersData = orderDataTemp
    },
    updateOrderStem(state, action: PayloadAction<IOrderStemSUpdate>) {
      state.ordersData?.forEach((item) => {
        action.payload.ids.forEach((s) => {
          const index = item.orderStems.findIndex((x) => x.id === s.id)
          if (index > -1) {
            item.orderStems[index].selected = s.selected
            if (s.price) {
              item.orderStems[index].price = s.price
            }
            if (s.quantity) {
              item.orderStems[index].quantity = s.quantity
            }
            if (typeof s.substitutes !== 'undefined') {
              item.orderStems[index].substitutes = s.substitutes
            }
            if (s.subStem) {
              item.orderStems[index].subStem = s.subStem
            }
            if (s.stemSubstitutes) {
              item.orderStems[index].stemSubstitutes = s.stemSubstitutes
            }
          }
        })
      })
    },
    updateOrderHardGood(state, action: PayloadAction<IOrderHardGoodsUpdate>) {
      action.payload.ids.forEach((s) => {
        const index = state.orderHardGoods.findIndex((x) => x.id === s.id)
        if (index > -1) {
          state.orderHardGoods[index].selected = s.selected
        }
      })
    },
    update1OrderHardGoodSupplierId(state, action: PayloadAction<IOrderHardGoodUpdateSupplierId>) {
      const orderDataTemp = [...(state.ordersData || [])]
      orderDataTemp[action.payload.orderIndex].orderHardGoods[action.payload.index].supplierId =
        action.payload.supplierId
      state.ordersData = orderDataTemp
    },
    update1OrderHardGoodQuantity(state, action: PayloadAction<IOrderHardGoodUpdateQuantity>) {
      const orderDataTemp = [...(state.ordersData || [])]
      orderDataTemp[action.payload.orderIndex].orderHardGoods[action.payload.index].quantity = action.payload.quantity
      state.ordersData = orderDataTemp
    },
    update1OrderHardGoodPrice(state, action: PayloadAction<IOrderHardGoodUpdatePrice>) {
      const orderDataTemp = [...(state.ordersData || [])]
      orderDataTemp[action.payload.orderIndex].orderHardGoods[action.payload.index].price = `${action.payload.price}`
      state.ordersData = orderDataTemp
    },
    setSupplierForGenericStem(state, action: PayloadAction<Supplier[]>) {
      state.supplierForGenericStem = action.payload
    },
    setOrderSuppliers(state, action: PayloadAction<OrderSupplier[]>) {
      state.orderSuppliers = action.payload
    },
    setSavingCount(state, action: PayloadAction<LOADING_STATUS>) {
      if (action.payload === LOADING_STATUS.START) {
        state.savingCount += 1
      } else {
        state.savingCount -= 1
      }
    },
    updateSingleOrderStem(state, action: PayloadAction<{ orderId: number; orderStemId: number; quantity: number }>) {
      if (state.ordersData) {
        const { orderId, orderStemId, quantity } = action.payload
        const orderIndex = state.ordersData.findIndex((o) => o.id === orderId)
        if (orderIndex > -1) {
          const orderStemIndex = state.ordersData[orderIndex].orderStems.findIndex((s) => s.id === orderStemId)
          if (orderStemIndex > -1) {
            state.ordersData[orderIndex].orderStems[orderStemIndex].quantity = quantity
          }
        }
      }
    }
  }
})

export const orderAction = orderSlice.actions

export default orderSlice.reducer
