/* eslint-disable @typescript-eslint/no-use-before-define */
import { takeEvery, all, call, put } from 'redux-saga/effects'
import { createAction } from '@reduxjs/toolkit'
import { IGetProductPayload, getListProducts } from 'src/api/products'
import { Product } from 'src/common/common.interface'
import { productCatalogAction } from './catalog.slide'

function* callGetListProducts({ payload }: ReturnType<typeof catalogActionSaga.getListProducts>) {
  try {
    yield put(productCatalogAction.queryProductData({ loading: true }))
    const response: { result: Product[] } = yield call(getListProducts, payload)
    yield put(productCatalogAction.queryProductData({ loading: false, products: response.result }))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getProposalTemplates', error)
  }
}

export const catalogActionSaga = {
  getListProducts: createAction<IGetProductPayload>('catalogActionSaga/getListProducts')
}

export default [
  function* fetchWatcher() {
    yield all([takeEvery(catalogActionSaga.getListProducts, callGetListProducts)])
  }
]
