import { Suspense, useEffect, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import Amplify, { Auth, Hub } from 'aws-amplify'
import * as Redux from 'react-redux'
import { useDispatch, useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import { createTheme, ThemeProvider, ThemeOptions } from '@material-ui/core'

import { configInterceptorsWithRedux } from 'src/api/apiLayer'

import configureStore from './store/configureStore'
import awsconfig from './aws-exports'
import './App.scss'
import { AppRouter } from './AppRouter'
import { UserContext } from './shared/context'
import { SentryInit } from './common/sentry'
import { appAction, selectorAppLoading, selectorIsDarkTheme, useAppSelector } from './store/app'
import { FullScreenLoading } from './components'

import { Trigger } from './pages/Trigger'
import ErrorPage from './pages/ErrorPage'
import Login from './pages/Login'
import SnackBarBase from './pages/Snackbar'

import { stemActionSaga } from './store/stem'
import { productActionSaga } from './store/product'
// ================
// AMPLIFY
// ================
awsconfig.oauth.redirectSignIn = `${window.location.origin}/`
awsconfig.oauth.redirectSignOut = `${window.location.origin}/`
Amplify.configure(awsconfig)

// ================
// CONFIG APP
// ================
SentryInit()
const store = configureStore()
configInterceptorsWithRedux(store)

// Define theme settings
const light: ThemeOptions = {
  palette: { type: 'light' }
}

const dark: ThemeOptions = {
  palette: { type: 'dark' }
}

function App() {
  const [user, setUser] = useState({})

  const dispatch = useDispatch()

  const appData = useAppSelector()
  const appLoading = useSelector(selectorAppLoading)
  const isDarkTheme = useSelector(selectorIsDarkTheme)

  function getUser() {
    return (
      Auth.currentAuthenticatedUser()
        .then((userData) => userData)
        // eslint-disable-next-line no-console
        .catch(() => console.log('Not signed in'))
    )
  }

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser().then((userData) => setUser(userData))
          break
        case 'signOut':
          setUser({})
          break
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          // eslint-disable-next-line no-console
          console.log('Sign in failure', data)
          break

        default:
          break
      }
    })

    dispatch(appAction.startLoading())

    dispatch(productActionSaga.getProductTypes())
    dispatch(stemActionSaga.getStemCategories())
    dispatch(stemActionSaga.listSupplierStems({ isAll: true }))
    dispatch(stemActionSaga.listAllSupplierWithPreferred())
    getUser()
      .then((userData) => setUser(userData))
      .finally(() => {
        dispatch(appAction.endLoading())
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ThemeProvider theme={isDarkTheme ? createTheme(dark) : createTheme(light)}>
      <Suspense fallback={<FullScreenLoading />}>
        <UserContext.Provider value={user}>
          {!user && <Login />}
          {appData.app.isErrorPage && <ErrorPage xAmznRequestId={appData.app.xAmznRequestId} />}
          {appLoading && <FullScreenLoading />}
          <SnackBarBase />
          <Trigger />
          {user && <AppRouter />}
        </UserContext.Provider>
      </Suspense>
    </ThemeProvider>
  )
}

const AppWrapper = () => {
  if (Cookies.get('theme') === 'dark') {
    document.body.classList.add('dark-theme')
  } else {
    document.body.classList.add('light-theme')
  }

  return (
    <Router>
      <Redux.Provider store={store}>
        <App />
      </Redux.Provider>
    </Router>
  )
}

export default Sentry.withProfiler(AppWrapper)
