/* eslint-disable no-await-in-loop */
import { getHttp } from './apiLayer'

export const handleGetAllData = async <T>(url: string, limit = 3000): Promise<T[]> => {
  let resultData: T[] = []

  const result = await getHttp(`/${url}?limit=${limit}&page=1`)
  resultData = resultData.concat(result.data?.result || [])

  const totalPage = Math.ceil((result.data?.count || 0) / limit)
  let currentPage = 2
  const arrayRequest = []

  while (currentPage <= totalPage) {
    arrayRequest.push(getHttp(`/${url}?limit=${limit}&page=${currentPage}`))
    currentPage++
  }

  const resultArrayRequest = await Promise.all(arrayRequest)

  resultArrayRequest.forEach((item) => {
    resultData = resultData.concat(item.data.result)
  })

  return resultData
}

export const handleGetAllDataWithCallback = async (callBack: Function, url: string, limit = 3000) => {
  const result = await getHttp(`/${url}?limit=${limit}&page=1`)

  const totalPage = Math.ceil((result.data?.count || 0) / limit)
  callBack && callBack(result?.data?.result ?? [], totalPage <= 1)

  for (let page = 2; page <= totalPage; page++) {
    const response = await getHttp(`/${url}?limit=${limit}&page=${page}`)
    // args1: data, args: check is end
    callBack && callBack(response.data?.result || [], page === totalPage)
  }
}
