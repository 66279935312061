import {
  ColorPalette,
  Pagination,
  PaginationParams,
  ProposalTemplate,
  TemplateProduct,
  TemplateProductPayload
} from 'src/common/common.interface'
import { getHttp, putHttp } from './apiLayer'

export const checkIsTemplateProduct = async (productId: number): Promise<boolean> => {
  const result = await getHttp(`products/${productId}/is-template-product`)
  return result.data
}

export const getListProposalTemplates = async (params: PaginationParams): Promise<Pagination<ProposalTemplate[]>> => {
  const result = await getHttp(`proposal-templates`, { params })
  return result.data
}

export const getListColorPalettes = async (params: PaginationParams): Promise<Pagination<ColorPalette[]>> => {
  const result = await getHttp(`color-palettes`, { params })
  return result.data
}

export const getTemplateProducts = async (templateId: number): Promise<Pagination<TemplateProduct[]>> => {
  const result = await getHttp(`proposal-templates/${templateId}/products`)
  return result.data
}

export const getProposalTemplate = async (templateId: number): Promise<ProposalTemplate> => {
  const result = await getHttp(`proposal-templates/${templateId}`)
  return result.data
}

export const updateProposalTemplate = async (
  templateId: number,
  payload: TemplateProductPayload
): Promise<ProposalTemplate> => {
  const result = await putHttp(`proposal-templates/${templateId}`, payload)
  return result.data
}
