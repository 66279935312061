import { BoxType } from 'src/pages/CreateOrder/CreateOrder'
import { getHttp, putHttp } from './apiLayer'

export interface IMarkAsConfirmedPayload {
  id: number
  boxCount?: number
  boxTypes?: BoxType
  deliveryDate?: Date
  loggedInEmail?: string
  eventOrderId?: number
}

export interface IConfirmShippingAddressPayload {
  designerName?: string
  deliveryAddress?: string
}

export interface IUpdateOrderSupplierPayload {
  orderState?: string
}

export const getOrderSuppliersByEventOrderId = async (eventOrderId: number) => {
  const data = await getHttp(`/orderSuppliers/${eventOrderId}`)
  return data.data
}

export const sendEmailOrderRecipients = async (
  eventId: number,
  supplierId: number,
  payload: IMarkAsConfirmedPayload
) => {
  const data = await putHttp(`/mailOrderRecipients/${eventId}/supplier/${supplierId}`, payload)
  return data.data
}

export const markAsConfirmed = async (eventId: number, supplierId: number, payload: IMarkAsConfirmedPayload) => {
  const data = await putHttp(`/markAsConfirmed/${eventId}/supplier/${supplierId}`, payload)
  return data.data
}

export const reOpenOrderSupplier = async (orderSupplierId: number, payload: IUpdateOrderSupplierPayload) => {
  const data = await putHttp(`/update-order-supplier/${orderSupplierId}`, payload)
  return data.data
}

export const confirmShippingAddress = async (eventOrderId: number, payload: IConfirmShippingAddressPayload) => {
  const data = await putHttp(`/confirmShippingAddress/${eventOrderId}`, payload)
  return data.data
}

export const setBatchingOrderSupplier = async (
  eventId: number,
  supplierId: number,
  payload: IMarkAsConfirmedPayload
) => {
  const data = await putHttp(`/setBatchedStateOrderSupplier/${eventId}/supplier/${supplierId}`, payload)
  return data.data
}
