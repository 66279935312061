import './Show.scss'

export interface ShowProps {
  heading: string
  data: string | undefined | string[]
}

export const Show = (props: ShowProps) => {
  const { data, heading } = props

  if (!data) {
    return null
  }
  return (
    <div className="show">
      <strong> {heading} </strong>
      {typeof data === 'object' ? (
        <div>
          {data.map((d) => {
            return (
              <p className="list-item" key={d}>
                {d}
              </p>
            )
          })}
        </div>
      ) : (
        <p> {data} </p>
      )}
    </div>
  )
}
