/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import ReactDOM from 'react-dom'

export const COPY_ELM_ID = 'copy-data'

export const renderCopyData = (
  element: React.FunctionComponentElement<any> | React.FunctionComponentElement<any>[]
) => {
  ReactDOM.render(element, document.getElementById(COPY_ELM_ID))
}
