import { SupplierStem } from '../common/common.interface'
import { deleteHttp, getHttp, patchHttp, postHttp, putHttp } from './apiLayer'

export interface SupplierStemsResponse {
  count: number
  result: SupplierStem[]
}

export interface ListSupplierStemsParams {
  page?: number
  limit?: number
  stemCategoryId?: number
  stemId?: number
  isAll?: boolean
}

export interface UpdateSupplierStemPayload {
  name?: string
  stemCategoryId?: number
  supplierRefId?: number
  stemId?: number
  color?: string[]
  length?: string
  pricePerStem?: number
  bunchSize?: number
  pricePerBunch?: number
  minimumOrder?: number
  inStock?: boolean
  supplier?: string
  origin?: string
  externalId?: string
  imageUrl?: string
  supplierId?: string
  defaultSubstitutes?: string
}

export interface CreateSupplierStemPayload {
  id?: number
  name: string
  stemCategoryId: number
  supplierRefId: number
  stemId: number
  color?: string[]
  length?: string
  pricePerStem?: number
  bunchSize?: number
  pricePerBunch?: number
  minimumOrder?: number
  inStock?: boolean
  supplier?: string
  origin?: string
  externalId?: string
  imageUrl?: string
  supplierId?: string
  defaultSubstitutes?: string
}

interface INewSupplierStem {
  stemId: number
  stemCategoryId: number
  supplierRefId: number
  name: string
}

export interface UpdateSupplierStemPricePayload {
  pricePerStem: number
  newSupplierStem?: INewSupplierStem
}

export const getListSupplierStems = async (params: ListSupplierStemsParams) => {
  const result = await getHttp('/stems/suppliers', { params })
  return result.data as SupplierStemsResponse
}

export const updateSupplerStem = async (supplierStemId: number, payload: UpdateSupplierStemPayload) => {
  const result = await patchHttp(`/stems/suppliers/${supplierStemId}`, payload)
  return result.data
}

export const deleteSupplerStem = async (supplierStemId: number) => {
  await deleteHttp(`/stems/suppliers/${supplierStemId}`)
}

export const createSupplerStem = async (payload: CreateSupplierStemPayload) => {
  const result = await postHttp(`/stems/suppliers`, payload)
  return result.data
}

export const updateSupplerStemPrice = async (supplierStemId: number, payload: UpdateSupplierStemPricePayload) => {
  const data = await putHttp(`/stems/suppliers/${supplierStemId}/update-price`, payload)
  return data.data
}
