import { PAGE_LIMIT } from 'src/common/constants'
import { postHttp, getHttp, putHttp } from './apiLayer'

interface ICreateHardGoodPayload {
  name: string
  type: string
  price: number
  description: string
}

interface IUpdateHardGoodPayload {
  name?: string
  type?: string
  price?: number
  description?: string
  sku?: string
}

export interface IGetHardGoodPayload {
  q?: string
  sortName?: string
  sortType?: string
  page?: number
  limit?: number
  isAll?: boolean
}

interface ICheckHardGoodSKUPayload {
  hardGoodId?: number
  sku: string
}

export const createHardGood = async (payload: ICreateHardGoodPayload) => {
  const data = await postHttp('/hard-goods', payload)
  return data.data
}

export const updateHardGood = async (hardGoodId: number, payload: IUpdateHardGoodPayload) => {
  const data = await putHttp(`/hard-goods/${hardGoodId}`, payload)
  return data.data
}

export const getListHardGoods = async (payload: IGetHardGoodPayload) => {
  let data
  if (payload.isAll) {
    data = await getHttp(`/hard-goods?isAll=${true}`)
  } else {
    data = await getHttp(
      `/hard-goods?q=${encodeURIComponent(payload.q || '')}&sortName=${payload.sortName}&sortType=${
        payload.sortType
      }&page=${payload.page || 1}&limit=${payload.limit || PAGE_LIMIT}`
    )
  }
  return data.data
}

export const checkHardGoodSKU = async (payload: ICheckHardGoodSKUPayload) => {
  const data = await postHttp(`/hard-goods/check-sku`, payload)
  return data.data
}
