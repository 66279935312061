import { PAGE_LIMIT } from 'src/common/constants'
import { StemErrorStatus } from 'src/common/enum'
import { postHttp, getHttp, putHttp, deleteHttp } from './apiLayer'
import { CreateSupplierStemPayload, UpdateSupplierStemPayload } from './supplierStem'

interface ICreateStemPayload {
  name: string
  stemCategoryId: number
  newStemCategory?: string
  color?: string[]
  type?: string[]
  variety?: string
  externalId?: string
  imageUrl?: string
  frequency?: string
  availability?: string[]
  pricePerStem?: number
  supplierStems?: CreateSupplierStemPayload[]
  supplierStemsUpdate?: UpdateSupplierStemPayload[]
}

export interface IGetStemPayload {
  q?: string
  cat?: string
  color?: string
  supplierIds?: string
  sortName?: string
  sortType?: string
  hasImage?: number
  deprecated?: boolean
  page?: number
  limit?: number
  isAll?: boolean
}

export interface UpdateStemErrorStatusPayload {
  errorStatus: StemErrorStatus
}

export interface UpdateStemPricePayload {
  pricePerStem: number
}

export interface ICheckStemAlreadyExistPayload {
  name: string
  stemId?: number[]
}

export const createStem = async (payload: ICreateStemPayload) => {
  const data = await postHttp('/stems', payload)
  return data.data
}

export const updateStem = async (stemId: number, payload: ICreateStemPayload) => {
  const data = await putHttp(`/stems/${stemId}`, payload)
  return data.data
}

export const deleteStem = async (stemId: number, isHardDelete?: boolean) => {
  const data = await deleteHttp(`/stems/${stemId}${isHardDelete ? '?isHardDelete=true' : ''}`)
  return data.data
}

export const getListStems = async (payload: IGetStemPayload) => {
  let data
  if (payload.isAll) {
    data = await getHttp(`/stems?isAll=${true}`)
  } else {
    data = await getHttp(
      `/stems?q=${encodeURIComponent(payload.q || '')}&cat=${payload.cat}&color=${payload.color}&supplierIds=${
        payload.supplierIds
      }&sortName=${payload.sortName}&sortType=${payload.sortType}&hasImage=${payload.hasImage || 0}&deprecated=${
        payload.deprecated || false
      }&page=${payload.page || 1}&limit=${payload.limit || PAGE_LIMIT}`
    )
  }
  return data.data
}

export const markAsDuplicateStem = async (oldStemId: number, newStemId: number) => {
  const data = await getHttp(`/stems/${oldStemId}/mark-as-duplicate/${newStemId}`)
  return data.data
}

export const isStemAlreadyExist = async (payload: ICheckStemAlreadyExistPayload) => {
  const data = await getHttp(`/stems/isAlreadyExist?stemName=${payload.name}&excludeId=${payload.stemId?.join() || []}`)
  return data.data
}

export const deDuplicationStemCategory = async (mergedInStemCategoryId: number, keepStemCategoryId: number) => {
  const data = await getHttp(`/stems/${mergedInStemCategoryId}/de-duplication/${keepStemCategoryId}`)
  return data.data
}

export const getDetailStem = async (stemId: number) => {
  const data = await getHttp(`/stems/${stemId}`)
  return data.data
}

export const updateStemErrorStatus = async (stemId: number, payload: UpdateStemErrorStatusPayload) => {
  const data = await putHttp(`/stems/${stemId}/update-error-status`, payload)
  return data.data
}

export const updateStemPrice = async (stemId: number, payload: UpdateStemPricePayload) => {
  const data = await putHttp(`/stems/${stemId}/update-price`, payload)
  return data.data
}
