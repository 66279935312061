import { useSelector } from 'react-redux'
import { RootState } from '../reducers'

export const selectorProductData = (state: RootState) => state.recipe.productData
export const selectorEventInfo = (state: RootState) => state.recipe.eventInfo
export const selectorProducts = (state: RootState) => state.recipe.products
export const selectorCurrentStemList = (state: RootState) => state.recipe.currentStemList
export const selectorEventOrderId = (state: RootState) => state.recipe.eventOrderId
export const selectorEventOrder = (state: RootState) => state.recipe.eventOrder
export const selectorSubmittedOrderSuppliers = (state: RootState) => state.recipe.submittedOrderSuppliers
export const selectorRecipeTemplate = (state: RootState) => state.recipe.recipeTemplate
export const selectorStemsOrder = (state: RootState) => state.recipe.stemsOrder
export const selectorSkeletonRecipeStem = (state: RootState) => state.recipe.skeletonRecipeStem
export const selectorCurrentColors = (state: RootState) => state.recipe.currentColors
export const selectorRecipeData = (state: RootState) => state.recipe
export const selectorAllSuppliers = (state: RootState) => state.recipe.allSuppliers
export const selectorCurrentSuppliers = (state: RootState) => state.recipe.currentSuppliers
export const selectorCurrentProduct = (state: RootState) => state.recipe.currentProduct
export const selectorLoadingProductData = (state: RootState) => state.recipe.loadingProductData
export const selectorLoadingStemData = (state: RootState) => state.recipe.loadingStemData
export const selectorLoadingSupplierStem = (state: RootState) => state.recipe.loadingSupplierStem
export const selectorIsSavingData = (state: RootState) =>
  state.recipe.savingDataCount > 0 || state.recipe.skeletonRecipeStem.length > 0

export const selectorCurrentRecipeId = (state: RootState) =>
  state.recipe.products && state.recipe.products.length ? state.recipe.products[0].recipeId : undefined

export const selectorStyleInformationData = (state: RootState) => state.recipe.styleInformation
export const selectorRecipeFormulas = (state: RootState) => state.recipe.recipeFormulas

export const useSelectorStemOrder = () => useSelector(selectorStemsOrder)
export const useSelectorCurrentColors = () => useSelector(selectorCurrentColors)
export const useSelectorAllSuppliers = () => useSelector(selectorAllSuppliers)
export const useSelectorProducts = () => useSelector(selectorProducts)
export const useSelectorCurrentProduct = () => useSelector(selectorCurrentProduct)
export const useSelectorEventOrderId = () => useSelector(selectorEventOrderId)
export const useSelectorEventOrder = () => useSelector(selectorEventOrder)
export const useSelectorSubmittedOrderSuppliers = () => useSelector(selectorSubmittedOrderSuppliers)
export const useSelectorLoadingProductData = () => useSelector(selectorLoadingProductData)
export const useSelectorLoadingStemData = () => useSelector(selectorLoadingStemData)
export const useSelectorLoadingSupplierStem = () => useSelector(selectorLoadingSupplierStem)
export const useSelectorIsSavingData = () => useSelector(selectorIsSavingData)
export const useSelectorEventInfo = () => useSelector(selectorEventInfo)
export const useSelectorCurrentRecipeId = () => useSelector(selectorCurrentRecipeId)
export const useSelectorStyleInformation = () => useSelector(selectorStyleInformationData)
export const useSelectorRecipeFormulas = () => useSelector(selectorRecipeFormulas)

export const useRecipeSelector = () => {
  return {
    productData: useSelector(selectorProductData),
    eventInfo: useSelector(selectorEventInfo),
    products: useSelector(selectorProducts),
    currentStemList: useSelector(selectorCurrentStemList),
    eventOrderId: useSelector(selectorEventOrderId),
    eventOrder: useSelector(selectorEventOrder),
    submittedOrderSuppliers: useSelector(selectorSubmittedOrderSuppliers),
    recipeTemplate: useSelector(selectorRecipeTemplate),
    stemsOrder: useSelector(selectorStemsOrder),
    skeletonRecipeStem: useSelector(selectorSkeletonRecipeStem),
    currentColors: useSelector(selectorCurrentColors),
    recipeData: useSelector(selectorRecipeData)
  }
}

export const useRecipeLittleDataChangesSelector = () => {
  return {
    eventInfo: useSelector(selectorEventInfo),
    productData: useSelector(selectorProductData),
    eventOrderId: useSelector(selectorEventOrderId),
    eventOrder: useSelector(selectorEventOrder),
    submittedOrderSuppliers: useSelector(selectorSubmittedOrderSuppliers),
    recipeTemplate: useSelector(selectorRecipeTemplate)
  }
}
